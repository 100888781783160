/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  BigPlayButton
} from "video-react";
// ui
import { Modal } from "antd";
import ContentPostPopup from "../ContentPostPopup";

class ViewVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onCloseModal() {
    this.player.pause();
    this.player.seek(0);
    this.props.onView(false);
  }

  render() {
    const { isView, onView, dataVideo, user, item } = this.props;
    return (
      <Modal
        wrapClassName="show-bg"
        className="popup-view-video"
        title={false}
        footer={false}
        centered
        closable={false}
        visible={isView}
        onOk={() => onView(false)}
        onCancel={() => this.onCloseModal()}
      >
        <div className="view-video">
          <Player
            poster=""
            ref={player => {
              this.player = player;
            }}
          >
            <source
              src={dataVideo && dataVideo.location ? dataVideo.location : ""}
            />
            {/* <source src="http://mirrorblender.top-ix.org/movies/sintel-1024-surround.mp4" /> */}
            <BigPlayButton position="center" icon="" />
            <ControlBar>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
              <VolumeMenuButton />
            </ControlBar>
          </Player>
        </div>

        <ContentPostPopup
          item={item}
          user={user}
          handleAddComment={this.props.handleAddComment}
          onLikePopup={this.props.onLikePopup}
          onDeleteComment={this.props.onDeleteComment}
          onUpdateComment={this.props.onUpdateComment}
          onLikeComment={this.props.onLikeComment}
          onLike={this.props.onLike}
        />
      </Modal>
    );
  }
}

export default ViewVideo;
