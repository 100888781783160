/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import { AiOutlineGoogle } from "react-icons/ai";
import GoogleLogin from "react-google-login";
import { Button } from "antd";
import { GOOGLE_CLIENT_ID } from "../../config";

const ButtonLoginGoogle = ({ onLoginWithGoogle, onLoginWithGoogleFailed }) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={renderProps => (
        <Button
          type="default"
          htmlType="submit"
          className="btn-login-google"
          block
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <div className="title-btn">
            <AiOutlineGoogle size={24} />
            <span>Login with Google</span>
          </div>
        </Button>
      )}
      buttonText="Login"
      onSuccess={onLoginWithGoogle}
      onFailure={onLoginWithGoogleFailed}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default ButtonLoginGoogle;
