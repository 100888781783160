import React from "react";
import "./index.scss";
// icons
import { IoIosAddCircleOutline } from "react-icons/io";
// ui
import { Menu, Layout, Badge } from "antd";
import { URL_MEDIA } from "../../../../config";

const { Sider } = Layout;

const SideLeft = () => {
  return (
    <Sider
      breakpoint="lg"
      width={300}
      className="site-layout-background side-left"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key="1">
          <div className="nav-item">
            {/* eslint-disable-next-line */}
            <img className="ic-bar" src={`${URL_MEDIA}images/home-a.svg`} />
            <span className="title">My Feed</span>
            <Badge count={25} style={{ background: "#3E7BFA" }} />
          </div>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="nav-item">
            {/* eslint-disable-next-line */}
            <img className="ic-bar" src={`${URL_MEDIA}images/lib.svg`} />

            <span className="title">Library</span>
            {/* <Badge count={25} style={{ background: "#3E7BFA" }} /> */}
          </div>
        </Menu.Item>
        <Menu.Item key="3">
          <div className="nav-item">
            {/* eslint-disable-next-line */}
            <img className="ic-bar" src={`${URL_MEDIA}images/explore.svg`} />

            <span className="title">Explore</span>
            {/* <Badge count={25} style={{ background: "#3E7BFA" }} /> */}
          </div>
        </Menu.Item>
      </Menu>

      <div className="my-classes">
        <div className="my-title">
          <h3>MY CLASSES</h3>
          <a href="/" className="btn-add">
            <IoIosAddCircleOutline /> Add
          </a>
        </div>
        <div className="list-classes">
          <a href="/" className="class-item">
            Trigonometry <span className="dot">&#183;</span>{" "}
            <span> Class X-B</span>
          </a>
          <a href="/" className="class-item">
            Arithmetics <span className="dot">&#183;</span>{" "}
            <span> Class X-B</span>
          </a>
          <a href="/" className="class-item">
            Problem Solving <span className="dot">&#183;</span>{" "}
            <span> Class X-B</span>
          </a>

          <a href="/" className="show-more">
            5 more classes
          </a>
        </div>
      </div>

      <div className="my-classes">
        <div className="my-title">
          <h3>MY GROUPS</h3>
          <a href="/" className="btn-add">
            <IoIosAddCircleOutline /> Add
          </a>
        </div>
        <div className="list-classes">
          <a href="/" className="class-item">
            School Teachers in Bangalore
          </a>
          <a href="/" className="class-item">
            Maths Teachers
          </a>
          <a href="/" className="class-item">
            Teaching Methodology
          </a>

          <a href="/" className="show-more">
            2 more classes
          </a>
        </div>
      </div>
    </Sider>
  );
};

export default SideLeft;
