const initData = {
  activitiesFeed: [],
  isCreatedActivity: false,
  activityFeed: null,
  isLikedActivity: false,
  isCommentActivity: false,
  isDeletedActivity: false,
  isUpdatedActivity: false,
  isLikedCommentActivity: false,
  isDeletedCommentActivity: false,
  isUpdatedCommentActivity: false,
  msgErr: null,
}

export default function (state = initData, action) {
  switch (action.type) {
    case 'ACTION_GET_ACTIVITIES_FEED':
      return {
        ...state,
        activitiesFeed: action.data,
      }
    case 'ACTION_RESET_ACTIVITIES_FEED':
      return {
        ...state,
        activitiesFeed: [],
      }
    case 'ERROR_GET_ACTIVITIES_FEED':
      return {
        ...state,
        activitiesFeed: [],
        msgErr: "Get activities failed!"
      }
    case 'ACTION_CREATE_ACTIVITY':
      return {
        ...state,
        isCreatedActivity: true,
        activityFeed: action.data
      }
    case 'ERROR_CREATE_ACTIVITY':
      return {
        ...state,
        isCreatedActivity: false,
        activityFeed: null,
        msgErr: "Created activity failed!"
      }
    case 'ACTION_LIKE_ACTIVITY':
      return {
        ...state,
        isLikedActivity: true,
      }
    case 'ERROR_LIKE_ACTIVITY':
      return {
        ...state,
        isLikedActivity: false
      }
    case 'ACTION_COMMENT_ACTIVITY':
      return {
        ...state,
        isCommentActivity: true,
      }
    case 'ERROR_COMMENT_ACTIVITY':
      return {
        ...state,
        isCommentActivity: false
      }
    case 'ACTION_DELETED_ACTIVITY':
      return {
        ...state,
        isDeletedActivity: true
      }
    case 'ERROR_DELETED_ACTIVITY':
      return {
        ...state,
        isDeletedActivity: false
      }
    case 'ACTION_UPDATED_ACTIVITY':
      return {
        ...state,
        isUpdatedActivity: true,
      }
    case 'ERROR_UPDATED_ACTIVITY':
      return {
        ...state,
        isUpdatedActivity: false
      }
    case 'ACTION_LIKED_COMMENT':
      return {
        ...state,
        isLikedCommentActivity: true
      }
    case 'ERROR_LIKED_COMMENT':
      return {
        ...state,
        isLikedCommentActivity: false
      }
    case 'ACTION_UPDATED_COMMENT':
      return {
        ...state,
        isUpdatedCommentActivity: true,
      }
    case 'ERROR_UPDATED_COMMENT':
      return {
        ...state,
        isUpdatedCommentActivity: false,
        msgErr: action.msgErr,
      }
    case 'ACTION_DELETED_COMMENT':
      return {
        ...state,
        isDeletedCommentActivity: true
      }
    case 'ERROR_DELETED_COMMENT':
      return {
        ...state,
        isDeletedCommentActivity: false,
        msgErr: action.msgErr
      }
    default:
      return {
        ...state
      }
  }
}
