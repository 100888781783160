/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
// ui
import { Modal } from "antd";
import { URL_MEDIA } from "../../config";
import ContentPostPopup from "../ContentPostPopup";

const ViewImage = props => {
  const { isView, onView, dataImage, user, item } = props;

  return (
    <Modal
      wrapClassName="show-bg"
      className="popup-view-image"
      title={false}
      footer={false}
      centered
      closable={false}
      visible={isView}
      onOk={() => onView(false)}
      onCancel={() => onView(false)}
    >
      <div className="view-image">
        <img
          src={dataImage ? dataImage.location : `${URL_MEDIA}images/act.png`}
          alt=""
        />
      </div>

      <ContentPostPopup
        item={item}
        user={user}
        handleAddComment={props.handleAddComment}
        onLikePopup={props.onLikePopup}
        onDeleteComment={props.onDeleteComment}
        onUpdateComment={props.onUpdateComment}
        onLikeComment={props.onLikeComment}
        onLike={props.onLike}
      />
    </Modal>
  );
};

export default ViewImage;
