/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import { connect } from "react-redux";
// actions
import { getUser } from "../../redux/actions/users";
import {
  getActiviesOfFeed,
  createActivityOfFeed,
} from "../../redux/actions/feeds";
// icons
// components
import HeaderPage from "./Layouts/Header";
import SideLeft from "./Layouts/SideLeft";
import Contacts from "./Layouts/Contacts";
import Footer from "./Layouts/Footer";
import Upcoming from "./Upcoming";
import Ads from "./Ads";
import FeedContent from "./FeedContent";
import FeedHashtag from "containers/Home/FeedsHashtag"
// ui
import { Layout, Col, Row } from "antd";
import { Switch, Route } from "react-router-dom";

const { Content } = Layout;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatedUser: false,
      user: null,
    };
    this.onShowSignUp = this.onShowSignUp.bind(this);
  }

  async componentDidMount() {
    await this.props.getUser();

    const { isRegister, user } = this.props;
    if (isRegister && user && user.role) {
      this.setState({ isCreatedUser: true });
    }

    (await this.props.user) === null && this.props.history.push("/");
  }


  onShowSignUp(isCreatedUser) {
    this.setState({ isCreatedUser });
  }

  onLogout() {
    localStorage.removeItem("_token_club");
    window.location.reload();
  }

  render() {
    const { isCreatedUser } = this.state;
    const { user, activitiesFeed } = this.props;

    return (
      <div className="home-page">
        <Layout>
          <HeaderPage user={user} />
          <Layout>
            <SideLeft />
            <Layout className="layout-content">
              <Content className="content-page">
                <Row gutter={24}>
                  <Col md={16}>
                    <Switch>
                      <Route path="/dashboard" exact >
                        <FeedContent user={user} />
                      </Route>
                      <Route path="/dashboard/hashtag" render={reanderPrps => <FeedHashtag user={user} {...reanderPrps} />} >
                      </Route>
                    </Switch>
                  </Col>
                  <Col md={8} className="content-page-upcoming">
                    <Upcoming />
                    <Ads />
                  </Col>
                </Row>
              </Content>
            </Layout>
            <Contacts />
          </Layout>
          <Footer className="home-page-footer" />
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    // users
    user: store.users.user,
    isRegister: store.users.isRegister,
    // feeds
    isCreatedActivity: store.feeds.isCreatedActivity,
    msgErrActivities: store.feeds.msgErr
  };
};

const mapDispatchToProps = {
  // users
  getUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
