import React from "react";
import { URL_MEDIA } from "../../../config";
import "./index.scss";

const Ads = () => {
  return (
    <div className="ads-page">
      <h2>Ad</h2>
      <div className="content">
        <img src={`${URL_MEDIA}images/p1.png`} alt="" />
      </div>
    </div>
  );
};

export default Ads;
