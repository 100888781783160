const initData = {
  user: null,
  users: [],
  mentionUsers: [],
  userInitCreate: null,
  isLogin: false,
  isRegister: false,
  msgErr: null,
  status: null
}

export default function (state = initData, action) {
  switch (action.type) {
    case 'ACTION_LOGIN_WITH_GOOGLE':
      return {
        ...state,
        user: action.data,
        isLogin: true,
        status: 'sign-in'
      }
    case 'ERROR_LOGIN_WITH_GOOGLE':
      return {
        ...state,
        user: null,
        msgErr: action.msgErr,
        isLogin: false,
        status: 'sign-in'
      }
    case "ACTION_SIGNUP_CHECK_AUTH":
      return {
        ...state,
        userInitCreate: action.data,
        status: 'check-auth'
      }
    case 'ERROR_SIGNUP_CHECK_AUTH':
      return {
        ...state,
        userInitCreate: null,
        msgErr: action.msgErr,
        status: 'check-auth'
      }
    case 'ACTION_LOGIN_SUCCESS':
      return {
        ...state,
        isLogin: true,
      }
    case 'ERROR_LOGIN':
      return {
        ...state,
        isLogin: false
      }
    case 'ACTION_SIGNUP_SUCCESS':
      return {
        ...state,
        isRegister: true,
        user: action.data,
        status: 'sign-up'
      }
    case 'ERROR_SIGNUP':
      return {
        ...state,
        isRegister: false,
        user: null,
        msgErr: action.msgErr
      }
    case 'ACTION_GET_PROFILE':
      return {
        ...state,
        user: action.data,
        status: 'sign-up'
      }
    case 'ERROR_GET_PROFILE':
      return {
        ...state,
        user: null,
        status: 'profile'
      }
    case 'ACTION_GET_ALL_USERS':
      return {
        ...state,
        users: action.data,
        status: 'get_all_user'
      }
    case 'ERROR_GET_ALL_USERS':
      return {
        ...state,
        users: [],
        status: 'error_all_users',
        msgErr: action.msgErr
      }
      case 'ACTION_GET_ALL_USERS_MENTION':
        return {
          ...state,
          mentionUsers: action.data,
          status: 'mention_users'
        }
        case 'ERROR_GET_ALL_USERS_MENTION':
          return {
            ...state,
            mentionUsers: [],
            status: 'error_mention_users',
            msgErr: action.msgErr
          }
    default:
      return {
        ...state
      }
  }
}