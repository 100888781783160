/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import { Switch, Route } from "react-router-dom";
import Profile from "./Profile";

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log(this.props);
    const { match } = this.props;

    return (
      <div className="accounts-page">
        <h1>Accounts</h1>
        <Switch>
          <Route
            path={`${match && match.url ? match.url : ""}/profile`}
            component={Profile}
          />
        </Switch>
      </div>
    );
  }
}

export default Accounts;
