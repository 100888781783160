import React, { Component } from "react";
import {
  Activity,
  CommentField,
  CommentList,
  FlatFeed,
  LikeButton,
  NotificationDropdown,
  StatusUpdateForm,
  StreamApp
} from "react-activity-feed";
import "react-activity-feed/dist/index.css";

import Linkify from "linkifyjs/react";

const content = "Hey @dhh, check out issue #23";
// const content = "#huylee http://google.com";

class EditorConvertToHTML extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // const activity = {
    //   actor: {
    //     data: {
    //       name: 'Nora Ferguson',
    //       profileImage: 'https://randomuser.me/api/portraits/women/72.jpg',
    //     },
    //   },
    //   verb: 'post',
    //   object: 'Just came back from this hike! #Hiking #Madeira',
    //   image:
    //     'https://handluggageonly.co.uk/wp-content/uploads/2017/08/IMG_0777.jpg',
    //   time: new Date(),
    // };

    return (
      <div>
        <Linkify
          tagName="div"
          options={{
            attributes: null,
            className: {
              url: "link",
              hashtag: "hashtag",
              mention: "mention"
            },
            defaultProtocol: "http, https, ftp, ftps",
            events: {
              click: function () {
                alert("Link clicked!");
              },
              mouseover: function () {
                alert("Link hovered!");
              }
            },
            format: {
              url: function (value) {
                return value.length > 50 ? value.slice(0, 50) + "…" : value;
              },
              hashtag: function (value) {
                return value;
              }
            },
            formatHref: {
              mention: function (href) {
                return "https://github.com" + href;
              },
              ticket: function (href) {
                return (
                  "https://github.com/SoapBox/linkifyjs/issues/" +
                  href.substring(1)
                );
              },
              url: function (href) {
                return href;
              }
            },
            ignoreTags: ["script", "style"],
            nl2br: false,
            tagName: {
              hashtag: "span",
              mention: "strong",
              url: "a",
              email: "a"
            },
            target: {
              url: "_blank",
              hashtag: null
            },
            validate: true
          }}
        >
          {content}
        </Linkify>

        <StreamApp
          apiKey="zqampg4mxeu8"
          appId="86558"
          token="eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoidXNlci1vbmUifQ.w_Bl3d5NaKYTM16-tjGct_ct4J55wUt3AuDTzB9o_7Q"
        >
          <NotificationDropdown notify />
          <StatusUpdateForm
            feedGroup="timeline"
            userId="user-one"
            doRequest={e => {
              console.log(e);
            }}
          />
          <FlatFeed
            options={{ reactions: { recent: true } }}
            notify
            Activity={props => (
              <Activity
                onClickHashtag={word => console.log(`clicked on ${word}`)}
                {...props}
                Footer={() => (
                  <div style={{ padding: "8px 16px" }}>
                    <LikeButton {...props} />
                    <CommentField
                      activity={props.activity}
                      onAddReaction={props.onAddReaction}
                    />
                    <CommentList activityId={props.activity.id} />
                  </div>
                )}
              />
            )}
          />
        </StreamApp>
      </div>
    );
  }
}

export default EditorConvertToHTML;
