/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import { Picker } from "emoji-mart";
import { URL_MEDIA } from "../../config";

import { EditorState, ContentState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
// icons
import { TiDelete } from "react-icons/ti";
import { FaVideo, FaRegFileExcel } from "react-icons/fa";
import { GrDocumentText, GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
// ui
import { Button, Col, message, Radio, Row, Upload } from "antd";

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLessPost: false,
      isShowEmoji: false,
      text: "",
      editorState: EditorState.createEmpty(),
      viewedBy: "teacher",
      isLoading: false,
      images: [],
      new_images: [],
      imagesData: [],
      attachments: [],
      new_attachments: [],
      videos: [],
      new_videos: []
    };
    this.wrapperRefPost = React.createRef();
    this.wrapperRefEmoji = React.createRef();
    this.addEmoji = this.addEmoji.bind(this);
    this.handleChangeText = this.handleChangeText.bind(this);
    this.onChangeViewed = this.onChangeViewed.bind(this);
    this.onShowPopupEmoji = this.onShowPopupEmoji.bind(this);
    this.onSubmitPost = this.onSubmitPost.bind(this);
    this.onBeforeUploadImages = this.onBeforeUploadImages.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onRemoveImageBase64 = this.onRemoveImageBase64.bind(this);
    this.onBeforeUploadFiles = this.onBeforeUploadFiles.bind(this);
    this.onRemoveFiles = this.onRemoveFiles.bind(this);
    this.onRemoveNewFiles = this.onRemoveNewFiles.bind(this);
    this.onRemoveVideos = this.onRemoveVideos.bind(this);
    this.onRemoveNewVideos = this.onRemoveNewVideos.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.renderIconTypeFile = this.renderIconTypeFile.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  async componentDidMount() {
    // document.addEventListener("click", this.handleClickOutside);
    const { text, images, attachments, videos, editorToRaw } = this.props;

    const contentState = convertFromRaw(JSON.parse(editorToRaw));
    const _editorState = EditorState.createWithContent(contentState);

    this.setState({
      text,
      images,
      attachments,
      videos,
      editorState: _editorState
    });
  }


  onEditorStateChange(editorState) {
    this.setState({
      editorState
    });
  }

  handleClickOutside(event) {
    if (
      !(
        this.wrapperRefPost &&
        this.wrapperRefPost.current &&
        this.wrapperRefPost.current.contains(event.target)
      )
    ) {
      if (this.state.text === "") {
        this.setState({ isShowLessPost: true, isShowEmoji: false });
      }
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  addEmoji(e) {
    let emoji = e.native;
    this.setState({
      text: this.state.text + emoji,
      isShowEmoji: false
    });
  }

  handleChangeText(e) {
    this.setState({ text: e.target.value });
  }

  onChangeViewed(e) {
    this.setState({ viewedBy: e.target.value });
  }

  onShowPopupEmoji(isShowEmoji) {
    this.setState({ isShowEmoji });
  }

  onBeforeUploadImages(image) {
    let checkTypeVideo = this.isVideo(image.name);
    if (checkTypeVideo) {
      const isLt25M = image.size / 1024 / 1024 < 25;
      if (!isLt25M) {
        message.error("Video must smaller than 25 MB!");
      } else {
        this.setState({ new_videos: [...this.state.new_videos, image] });
        return false;
      }
    } else {
      const isLt4M = image.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        message.error("Image must smaller than 4 MB!");
      } else {
        this.setState({ new_images: [...this.state.new_images, image] });

        this.getBase64(image, imageUrl =>
          this.setState({
            imagesData: [...this.state.imagesData, imageUrl]
          })
        );

        return false;
      }
    }
  }

  onBeforeUploadFiles(file) {
    const isLt25M = file.size / 1024 / 1024 < 25;
    if (!isLt25M) {
      message.error("File must smaller than 25 MB!");
    } else {
      this.setState({ new_attachments: [...this.state.new_attachments, file] });
      return false;
    }
  }

  onRemoveImage(index) {
    const { images } = this.state;
    images.splice(index, 1);
    // imagesData.splice(index, 1);
    this.setState({ images });
  }

  onRemoveImageBase64(index) {
    const { new_images, imagesData } = this.state;
    new_images.splice(index, 1);
    imagesData.splice(index, 1);
    this.setState({ imagesData });
  }

  onRemoveVideos(index) {
    const { videos } = this.state;
    videos.splice(index, 1);
    this.setState({ videos });
  }

  onRemoveNewVideos(index) {
    const { new_videos } = this.state;
    new_videos.splice(index, 1);
    this.setState({ new_videos });
  }

  onRemoveFiles(index) {
    const { attachments } = this.state;
    attachments.splice(index, 1);
    this.setState({ attachments });
  }

  onRemoveNewFiles(index) {
    const { new_attachments } = this.state;
    new_attachments.splice(index, 1);
    this.setState({ new_attachments });
  }

  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  isVideo(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
      default:
        return false;
    }
  }

  renderIconTypeFile(name) {
    let arr = name.split(".");
    let ext = arr[arr.length - 1].toLowerCase();
    console.log("ext: ", ext);
    if (ext === "xlsx") {
      return <FaRegFileExcel />;
    } else if (ext === "pdf") {
      return <GrDocumentPdf />;
    } else if (ext === "csv") {
      return <GrDocumentCsv />;
    } else {
      return <GrDocumentText />;
    }
  }

  async onSubmitPost() {
    const {
      text,
      images,
      attachments,
      videos,
      new_images,
      new_attachments,
      new_videos,
      editorState
    } = this.state;

    console.log(
      images,
      attachments,
      videos,
      new_images,
      new_attachments,
      new_videos
    );

    this.setState({ isLoading: true });

    const contentState = editorState.getCurrentContent();
    const contentText = contentState.getPlainText('\u0001');
    const rawContentState = convertToRaw(contentState);

    let fd = new FormData();
    fd.append("content", text);
    fd.append("contentText", contentText);
    fd.append("editorToRaw", JSON.stringify(rawContentState));

    if (images) {
      fd.append("old_images", JSON.stringify(images));
    }
    if (attachments) {
      fd.append("old_attachments", JSON.stringify(attachments));
    }
    if (videos) {
      fd.append("old_videos", JSON.stringify(videos));
    }

    for (let i = 0; i < new_images.length; i++) {
      const img = new_images[i];
      fd.append("images", img);
    }

    for (let i = 0; i < new_attachments.length; i++) {
      const img = new_attachments[i];
      fd.append("attachments", img);
    }

    for (let i = 0; i < new_videos.length; i++) {
      const video = new_videos[i];
      fd.append("videos", video);
    }

    this.props.onUpdateActivity(this.props.activityId, fd);
  }

  render() {
    const { user, mentionUsers } = this.props;
    const {
      editorState,
      isShowEmoji,
      viewedBy,
      images,
      imagesData,
      attachments,
      new_attachments,
      videos,
      new_videos
    } = this.state;

    console.log("editorState: ", editorState);
    console.log("props: ", this.props);

    return (
      <div className="edit-post">
        <div className="post-activity-full" ref={this.wrapperRefPost}>
          <div className="post-top">
            {/* <TextArea
              value={text}
              placeholder={`Share what’s on your mind with other ${
                user ? user.role : ""
              }s`}
              onChange={this.handleChangeText}
              autoSize={{ minRows: 4, maxRows: 25 }}
            /> */}

            <Editor
              placeholder={`Share what’s on your mind with other ${
                user && user.role ? user.role : ""
                }s`}
              editorState={editorState}
              wrapperClassName="post-wrapper"
              editorClassName="post-editor"
              onEditorStateChange={this.onEditorStateChange}
              // toolbarHidden
              mention={{
                separator: " ",
                trigger: "@",
                suggestions: mentionUsers
              }}
              hashtag={{
                separator: " ",
                trigger: "#"
              }}
            />

            {/* view images upload here */}
            {images && images.length > 0 && (
              <div className="view-images-uploading">
                {images.map((img, i) => {
                  if (img.location) {
                    return (
                      <div key={i} className="img-item">
                        <Button
                          type="link"
                          className="btn-remove"
                          onClick={() => this.onRemoveImage(i)}
                        >
                          <TiDelete />
                        </Button>
                        <img src={img.location} alt="" />
                      </div>
                    );
                  } else {
                    return "";
                  }
                })}
              </div>
            )}

            {imagesData.length > 0 && (
              <div className="view-images-uploading">
                {imagesData.map((url, i) => (
                  <div key={i} className="img-item">
                    <Button
                      type="link"
                      className="btn-remove"
                      onClick={() => this.onRemoveImageBase64(i)}
                    >
                      <TiDelete />
                    </Button>
                    <img src={url} alt="" />
                  </div>
                ))}
              </div>
            )}

            {videos && videos.length > 0 && (
              <div className="view-attachments-uploading view-videos-uploading">
                {videos.map((file, f) => (
                  <div key={f} className="file-item">
                    <div className="file-type-icon">
                      <FaVideo />
                    </div>
                    <div className="file-info">
                      <h3>{file.originalname}</h3>
                      <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                      <Button
                        type="link"
                        className="btn-remove"
                        onClick={() => this.onRemoveVideos(f)}
                      >
                        <TiDelete />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {new_videos.length > 0 && (
              <div className="view-attachments-uploading view-videos-uploading">
                {new_videos.map((file, f) => (
                  <div key={f} className="file-item">
                    <div className="file-type-icon">
                      <FaVideo />
                    </div>
                    <div className="file-info">
                      <h3>{file.name}</h3>
                      <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                      <Button
                        type="link"
                        className="btn-remove"
                        onClick={() => this.onRemoveVideos(f)}
                      >
                        <TiDelete />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* view attachments upload */}
            {attachments && attachments.length > 0 && (
              <div className="view-attachments-uploading">
                {attachments.map((file, f) => (
                  <div key={f} className="file-item">
                    <div className="file-type-icon">
                      {this.renderIconTypeFile(file.originalname)}
                    </div>
                    <div className="file-info">
                      <h3>{file.originalname}</h3>
                      <p>{(file.size / 1024).toFixed(2)} KB</p>
                      <Button
                        type="link"
                        className="btn-remove"
                        onClick={() => this.onRemoveFiles(f)}
                      >
                        <TiDelete />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {new_attachments.length > 0 && (
              <div className="view-attachments-uploading">
                {new_attachments.map((file, f) => (
                  <div key={f} className="file-item">
                    <div className="file-type-icon">
                      {this.renderIconTypeFile(file.name)}
                    </div>
                    <div className="file-info">
                      <h3>{file.name}</h3>
                      <p>{(file.size / 1024).toFixed(2)} KB</p>
                      <Button
                        type="link"
                        className="btn-remove"
                        onClick={() => this.onRemoveNewFiles(f)}
                      >
                        <TiDelete />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="post-actions">
              <div className="actions-left">
                <Upload
                  // disabled={images.length > 4}
                  beforeUpload={file => this.onBeforeUploadImages(file)}
                  accept=".png, .jpg, .jpeg, video/*"
                  showUploadList={false}
                >
                  <div className="act-icon">
                    <img src={`${URL_MEDIA}images/img.svg`} alt="" />
                    <span>Add photo/video</span>
                  </div>
                </Upload>

                <Upload
                  // disabled={attachments.length > 4}
                  beforeUpload={file => this.onBeforeUploadFiles(file)}
                  accept="application/pdf,
                      .csv,
                      .xlsx,
                      .xls,
                      application/vnd.ms-excel
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  showUploadList={false}
                >
                  <div className="act-icon">
                    <img src={`${URL_MEDIA}images/docs.svg`} alt="" />
                    {/* <MdImage /> */}
                    <span>Add Document</span>
                  </div>
                </Upload>
              </div>
              <div className="actions-right">
                <div className="act-icon act-emoji">
                  <img
                    ref={this.wrapperRefEmoji}
                    src={`${URL_MEDIA}images/smile.svg`}
                    alt=""
                    className="show-emoji"
                    onClick={() =>
                      this.setState({
                        isShowEmoji: !this.state.isShowEmoji
                      })
                    }
                  />
                </div>
                {isShowEmoji && (
                  <div className="emojis" ref={this.wrapperRefEmoji}>
                    <Picker
                      onSelect={this.addEmoji}
                      set="twitter"
                      theme="light"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="post-bottom">
            <Row gutter={24}>
              <Col md={18}>
                <div className="viewed-option">
                  <h3>This post can be viewed by</h3>
                  <Radio.Group
                    onChange={this.onChangeViewed}
                    value={viewedBy}
                    disabled
                  >
                    <Radio value="teacher">Any Teacher on Cultural Club</Radio>
                    <Radio value="any">Only your Connections</Radio>
                  </Radio.Group>
                </div>
              </Col>
              <Col md={6}>
                <div className="act-submit">
                  <Button type="primary" onClick={() => this.onSubmitPost()}>
                    UPDATE
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPost;
