/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { connect } from "react-redux";
import { URL_MEDIA } from "../../../config";

// actions
import {
    createActivityOfFeed,
    getActiviesOfFeedHashtag,
    deleteActivity,
    likeActivity,
    updateActivity,
    commentActivity,
    likeCommentOfActivity,
    deleteCommentOfActivity,
    updateCommentOfActivity
} from "../../../redux/actions/feeds";
import { getAllUsersMention } from "../../../redux/actions/users";

// ui
import {
    Empty,
    message,
    Spin
} from "antd";
import ActivityItem from "../../../components/ActivityItem";


const FeedsHashtag = (props) => {
    const { user, activitiesFeed, mentionUsers, location } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [activityId, setActivityId] = useState(null);



    useEffect(() => {
        (async () => {
            await props.getActiviesOfFeedHashtag(location.hash);
            setIsLoading(false);
        })();
    }, [location.hash])


    const onUpdateComment = async (commentId, data) => {
        await props.updateCommentOfActivity(commentId, data);
        (await props.isUpdatedCommentActivity) === true
            ? message.success("Updated comment")
            : message.error("Update comment failed!");
        (await props.isUpdatedCommentActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    const onDeleteComment = async (commentId) => {
        await props.deleteCommentOfActivity(commentId);
        (await props.isDeletedCommentActivity) === true
            ? message.success("Deleted!")
            : message.error("Delete comment failed!");
        (await props.isDeletedCommentActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    const onEditActivity = (activityId) => {
        setActivityId(activityId);
    }

    const onLike = async (activityId) => {
        await props.likeActivity(activityId);
        (await props.isLikedActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    const onAddComment = async (activityId, data) => {
        await props.commentActivity(activityId, data);
        (await props.isCommentActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    const onDeleteActivity = async (activityId) => {
        console.log("activityId: ", activityId);
        await props.deleteActivity(activityId);
        (await props.isDeletedActivity) === true
            ? message.success("Deleted activity!")
            : message.error("Deleted activity failed!");
        (await props.isDeletedActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    const onUpdateActivity = async (activityId, data) => {
        console.log(activityId, data);
        await props.updateActivity(activityId, data);
        (await props.isUpdatedActivity) === true
            ? message.success("Updated activity successfully!")
            : message.error("Updated activity failed!");
        (await props.isUpdatedActivity) === true &&
            setActivityId(null);
        (await props.isUpdatedActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }


    const onLikeComment = async (commentId) => {
        await props.likeCommentOfActivity(commentId);
        (await props.isLikedCommentActivity) === true &&
            props.getActiviesOfFeedHashtag(location.hash);
    }

    return (
        <Spin spinning={isLoading}>
            <div className={styles["feed-ctn"]}>

                <div className={styles.hashtagContainer}>
                    <div className={styles.hashtag}>
                        <span className={styles.hashtagCurrent}>{location.hash}</span>
                        <span className={styles.hashtagText}>HASHTAG</span>
                    </div>
                    <span className={styles.hashtagPostCount}>{`${activitiesFeed.length} ${activitiesFeed.length > 1 ? "Posts" : "Post"}`}</span>
                </div>

                <div className={styles["list-activities"]}>
                    {activitiesFeed && activitiesFeed.length > 0 ? (
                        activitiesFeed.map((item, i) => (
                            <ActivityItem
                                key={i}
                                item={item}
                                user={user}
                                activityId={activityId}
                                onLike={onLike}
                                onDeleteActivity={onDeleteActivity}
                                onUpdateActivity={onUpdateActivity}
                                onEditActivity={onEditActivity}
                                onAddComment={onAddComment}
                                onLikeComment={onLikeComment}
                                onDeleteComment={onDeleteComment}
                                onUpdateComment={onUpdateComment}
                                mentionUsers={mentionUsers}
                            />
                        ))
                    ) : (
                            <Empty key={0} />
                        )}
                </div>
            </div>
        </Spin>
    );
}

const mapStateToProps = store => {
    return {
        activityFeed: store.feeds.activityFeed,
        activitiesFeed: store.feeds.activitiesFeed,
        isCreatedActivity: store.feeds.isCreatedActivity,
        isLikedActivity: store.feeds.isLikedActivity,
        isCommentActivity: store.feeds.isCommentActivity,
        isDeletedActivity: store.feeds.isDeletedActivity,
        isUpdatedActivity: store.feeds.isUpdatedActivity,
        isLikedCommentActivity: store.feeds.isLikedCommentActivity,
        isDeletedCommentActivity: store.feeds.isDeletedCommentActivity,
        isUpdatedCommentActivity: store.feeds.isUpdatedCommentActivity,
        // user
        mentionUsers: store.users.mentionUsers,
        // error
        msgErr: store.feeds.msgErr
    };
};

const mapDispatchToProps = {
    createActivityOfFeed,
    getActiviesOfFeedHashtag,
    deleteActivity,
    likeActivity,
    updateActivity,
    commentActivity,
    likeCommentOfActivity,
    deleteCommentOfActivity,
    updateCommentOfActivity,
    getAllUsersMention
};

export default connect(mapStateToProps, mapDispatchToProps)(FeedsHashtag);