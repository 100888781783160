/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./index.scss";
import { URL_MEDIA } from "../../config";

// editor
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

// icons
import { AiOutlineSend } from "react-icons/ai";
// ui
import { Avatar, Button } from "antd";

const AddComment = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [elRef, setRef] = useState();

  const {
    mentionUsers,
    user: { avatar }
  } = props;

  const onSubmit = async () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const hashtagConfig = {
      trigger: "#",
      separator: " "
    };

    const { blocks } = rawContentState;
    console.log("text: ", blocks[0].text.trim());
    rawContentState.blocks[0].text = blocks[0].text.trim();
    console.log(rawContentState);
    const markup = draftToHtml(rawContentState, hashtagConfig);

    if (blocks[0].text.trim() !== "") {
      console.log("markup: ", markup);
      await props.onComment(markup);
      await setEditorState(EditorState.createEmpty());
    }
  };

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  return (
    <div className="add-comment">
      <Avatar
        src={avatar ? avatar.location : `${URL_MEDIA}images/user.svg`}
        size={32}
      />
      <div className="input-comment">
        <Editor
          placeholder="+ Add a Comment"
          editorState={editorState}
          wrapperClassName="post-wrapper"
          editorClassName="post-editor"
          onEditorStateChange={onEditorStateChange}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: mentionUsers
          }}
          locale={{}}
          // hashtag={{
          //   separator: " ",
          //   trigger: "#"
          // }}
          toolbar={{
            options: ["emoji"],
            emoji: {
              className: "emoji-option-custom",
              popupClassName: "emoji-popup-custom"
            }
          }}
        />
      </div>
      <Button type="link" className="btn-send" onClick={onSubmit}>
        <AiOutlineSend />
      </Button>
    </div>
  );
};

export default AddComment;
