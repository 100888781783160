/* eslint-disable no-unused-vars */
import API from '../request'

export function loginWithGoogle(data) {
  return dispatch => {
    return API().post(`users/login-with-google`, data).then(res => {
      dispatch({
        type: "ACTION_LOGIN_WITH_GOOGLE",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_LOGIN_WITH_GOOGLE",
        msgErr: err
      })
    })
  }
}

export function signupCheckAuth(data) {
  return dispatch => {
    return API().post(`users/signup-checked-auth`, data).then(res => {
      dispatch({
        type: "ACTION_SIGNUP_CHECK_AUTH",
        data: data
        // data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_SIGNUP_CHECK_AUTH",
        msgErr: err.message
      })
    })
  }
}

export function signupInfoAndRelationship(data) {
  return dispatch => {
    return API().post(`users/signup`, data).then(res => {
      dispatch({
        type: "ACTION_SIGNUP_SUCCESS",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_SIGNUP",
        msgErr: "Signup failed. Please try again!"
      })
    })
  }
}

export function getUser() {
  return dispatch => {
    return API().get(`users/profile`).then(res => {
      dispatch({
        type: "ACTION_GET_PROFILE",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_GET_PROFILE",
        msgErr: "Get data profile failed!"
      })
    })
  }
}

export function getAllUsers() {
  return dispatch => {
    return API().get(`users/`).then(res => {
      dispatch({
        type: "ACTION_GET_ALL_USERS",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_GET_ALL_USERS",
        msgErr: 'Get all users failed!'
      })
    })
  }
}

export function getAllUsersMention() {
  return dispatch => {
    return API().get(`users/mention`).then(res => {
      dispatch({
        type: "ACTION_GET_ALL_USERS_MENTION",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_GET_ALL_USERS_MENTION",
        msgErr: 'Get all users mention failed!'
      })
    })
  }
}

export default {
  signupCheckAuth,
  signupInfoAndRelationship,
  // auth
  getUser,
  getAllUsers,
  getAllUsersMention,
}