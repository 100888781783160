import React from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { URL_MEDIA } from "../../../config";
import "./index.scss";

const Upcoming = () => {
  return (
    <div className="upcoming">
      <div className="title-box">
        <h3>Upcoming</h3>
        <a href="/" className="btn-add">
          <IoIosAddCircleOutline /> Add
        </a>
      </div>
      <div className="values">
        <div className="coming-item">
          <img
            src={`${URL_MEDIA}images/calendar.svg`}
            alt=""
            className="ic-can"
          />
          <div className="info">
            <h3>Online Trigonometry Class</h3>
            <p>23.08.2020 at 9 AM</p>
          </div>
        </div>
        <div className="coming-item">
          <img
            src={`${URL_MEDIA}images/calendar.svg`}
            alt=""
            className="ic-can"
          />
          <div className="info">
            <h3>Class Assignment Evaluation</h3>
            <p>23.08.2020 at 10 AM</p>
          </div>
        </div>
        <div className="coming-item">
          <img
            src={`${URL_MEDIA}images/calendar.svg`}
            alt=""
            className="ic-can"
          />
          <div className="info">
            <h3>Seminar for last day of School</h3>
            <p>23.08.2020 at 11 AM</p>
          </div>
        </div>

        <a href="/" className="show-more">
          2 more classes
        </a>
      </div>
    </div>
  );
};

export default Upcoming;
