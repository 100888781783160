/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./index.scss";
import { URL_MEDIA } from "../../config";
import { connect } from "react-redux";
// actions
import { signupCheckAuth, loginWithGoogle } from "../../redux/actions/users";

// components
import ButtonLoginGoogle from "../../components/ButtonLoginGoogle";
import ButtonRegisterWithGoogle from "../../components/ButtonRegisterWithGoogle";
import SignUpFailed from "./SignUpFailed";
// ui
import { Radio, Card, message } from "antd";

const SignUp = props => {
  const [role, setRole] = useState("student");
  const [isPopupError, setPopupError] = useState(false);

  useEffect(() => {
    if (props.user && props.isLogin === true) {
      message.success("Login successfully!");
      localStorage.setItem("_token_club", props.user._token);
      props.history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, [props.isLogin, props.user]);

  useEffect(() => {
    if (props.msgErr && props.status === "check-auth") {
      setPopupError(true);
    }
  }, [props.msgErr, props.status]);

  useEffect(() => {
    if (props.msgErr && props.status === "sign-in") {
      message.error(props.msgErr);
    }
  }, [props.msgErr, props.status]);

  useEffect(() => {
    if (props.userInitCreate) {
      props.history.push(`/auth/${role}`);
    }
    // eslint-disable-next-line
  }, [props.userInitCreate]);

  const responseGoogle = async response => {
    console.log(response);

    const {
      profileObj: { email },
      accessToken,
      googleId,
      tokenId
    } = response;
    props.signupCheckAuth({ role, email, accessToken, googleId, tokenId });
  };

  const responseFailedGoogle = response => {
    console.log(response);
  };

  const onLoginWithGoogle = async response => {
    const {
      profileObj: { email },
      googleId
    } = response;

    props.loginWithGoogle({ email, googleId });
  };

  const onLoginWithGoogleFailed = res => {
    console.log(res);
    // message.error("Login failed! Please try again");
  };

  return (
    <div className="sign-up-page">
      {isPopupError && (
        <SignUpFailed
          msgErr={props.msgErr}
          responseGoogle={responseGoogle}
          responseFailedGoogle={responseFailedGoogle}
          onLoginWithGoogle={onLoginWithGoogle}
          onLoginWithGoogleFailed={onLoginWithGoogleFailed}
        />
      )}

      {isPopupError === false && (
        <div className="sign-up-content">
          <Card
            title={
              <div className="logo">
                <img src={`${URL_MEDIA}images/logo.svg`} alt="" />
                <h3>Cultural Club</h3>
              </div>
            }
          >
            <div className="form-sign-up">
              <h3>SIGN UP</h3>
              <h5>Select your account type to continue</h5>

              <Radio.Group onChange={e => setRole(e.target.value)} value={role}>
                <Radio value="teacher">Teacher</Radio>
                <p className="decs">
                  For teachers and lecturers who wants to teach and build
                  network or community.
                </p>
                <Radio value="student">Student</Radio>
                <p className="decs">
                  For students who wants to build network or community with
                  other students and join classes
                </p>
                <Radio value="parent">Parent</Radio>
                <p className="decs">
                  For parents who wants to build network or community with other
                  parents and track children
                </p>
              </Radio.Group>

              <div className="actions">
                <ButtonRegisterWithGoogle
                  responseGoogle={responseGoogle}
                  responseFailedGoogle={responseFailedGoogle}
                />
                <ButtonLoginGoogle
                  onLoginWithGoogle={onLoginWithGoogle}
                  onLoginWithGoogleFailed={onLoginWithGoogleFailed}
                />
              </div>
              <p className="more">
                If you don’t have a Google account,{" "}
                <a
                  href="https://accounts.google.com/signup?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  click here
                </a>{" "}
                to create an account
              </p>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = store => {
  return {
    // users
    userInitCreate: store.users.userInitCreate,
    msgErr: store.users.msgErr,
    user: store.users.user,
    isLogin: store.users.isLogin,
    status: store.users.status
  };
};

const mapDispatchToProps = {
  signupCheckAuth,
  loginWithGoogle
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
