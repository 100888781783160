// import { ReactTinyLink } from 'react-tiny-link'
import createLinkifyPlugin from 'draft-js-linkify-plugin';
/* eslint-disable no-undef */
let url;
if (process.env.NODE_ENV === 'development') {
  url = process.env.REACT_APP_URL_API_DEV_API;
} else {
  url = process.env.REACT_APP_URL_API_PROD_API;
}

export const URL_API = url;

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const URL_ROOT = process.env.REACT_APP_URL_ROOT;
export const URL_MEDIA = process.env.REACT_APP_URL_MEDIA;

export const GETSTREAM_APP_ID = process.env.REACT_APP_GET_GETSTREAM_APP_ID;
export const GETSTREAM_APP_KEY = process.env.REACT_APP_GET_STREAM_KEY;

// plugins
export const linkifyPlugin = createLinkifyPlugin();

export const linkifyOptions = {
  attributes: null,
  className: {
    url: "link",
    hashtag: "hashtag",
    mention: "mention"
  },
  defaultProtocol: "http, https, ftp, ftps",
  events: {
    click: function () {
      alert("Link clicked!");
    },
    mouseover: function () {
      alert("Link hovered!");
    }
  },
  format: {
    url: function (value) {
      return value.length > 50 ? value.slice(0, 50) + "…" : value;
    },
    hashtag: function (value) {
      return value;
    }
  },
  formatHref: {
    mention: function (href) {
      return "https://github.com" + href;
    },
    ticket: function (href) {
      return (
        "https://github.com/SoapBox/linkifyjs/issues/" + href.substring(1)
      );
    },
    url: function (href) {
      return href;
    }
  },
  ignoreTags: ["script", "style"],
  nl2br: false,
  tagName: {
    hashtag: "span",
    mention: "strong",
    url: "a",
    email: "a"
  },
  target: {
    url: "_blank",
    hashtag: null
  },
  validate: true
}

export default {
  URL_ROOT,
  URL_MEDIA,
  URL_API,
  GOOGLE_CLIENT_ID,
  // get stream
  GETSTREAM_APP_ID,
  GETSTREAM_APP_KEY,

  linkifyOptions,
  // plugins
  // linkifyPlugin,
}