/* eslint-disable react/prop-types */
import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./index.scss";
import { URL_MEDIA } from "../../../../config";
// ui
import { Layout, Row, Col, Avatar, Input, Badge, Dropdown, Menu } from "antd";
import { AiOutlineLeft } from "react-icons/ai";

const { Header } = Layout;
const { Search } = Input;

const HeaderPage = props => {
  const location = useLocation()
  return (
    <Header className="header-page">
      <Row gutter={24}>
        <Col md={7}>
          {location.pathname === "/dashboard/hashtag" ? (
            <span className="returnTextAndbackIcon">
              <Link to="/dashboard"><AiOutlineLeft color="gray" /> <span className="returnText">back to feed</span></Link>
            </span>
          ) : (
              <div className="logo">
                <img src={`${URL_MEDIA}images/logo1.svg`} alt="" />
              </div>
            )}
        </Col>
        <Col md={10}>
          <Search
            suffix={
              // eslint-disable-next-line
              <img
                className="ic-search"
                src={`${URL_MEDIA}images/search.svg`}
              />
            }
            className="search-bar"
            placeholder="Search for people, documents, posts, etc"
            onSearch={value => console.log(value)}
          />
        </Col>
        <Col md={7}>
          <div className="hd-right">
            <Badge count={2}>
              <a href="/" className="messages">
                {/* eslint-disable-next-line */}
                <img src={`${URL_MEDIA}images/bell.svg`} alt="" />
              </a>
            </Badge>

            <Badge count={8}>
              <a href="/" className="messages">
                {/* eslint-disable-next-line */}
                <img src={`${URL_MEDIA}images/msg.svg`} alt="" />
              </a>
            </Badge>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="0">
                    <a href="http://www.alipay.com/">Profile</a>
                  </Menu.Item>
                  <Menu.Item key="1">
                    <a href="http://www.taobao.com/">Setting</a>
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item
                    key="3"
                    onClick={() => {
                      localStorage.removeItem("_token_club");
                      window.location.reload();
                    }}
                  >
                    Logout
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              {/* eslint-disable-next-line */}
              <a
                className="ant-dropdown-link"
                onClick={e => e.preventDefault()}
              >
                <Avatar
                  size={32}
                  src={
                    props.user && props.user.avatar
                      ? props.user.avatar.location
                      : `${URL_MEDIA}images/user.svg`
                  }
                />
              </a>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </Header >
  );
};

export default HeaderPage;
