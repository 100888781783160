import React from "react";
import "./index.scss";
// icons
// ui
import { Layout, Input, Avatar } from "antd";
import { URL_MEDIA } from "../../../../config";

const { Sider } = Layout;
const { Search } = Input;

const Contacts = () => {
  return (
    <Sider
      breakpoint="lg"
      width={300}
      className="site-layout-background contacts"
    >
      <Search
        suffix={
          // eslint-disable-next-line
          <img className="ic-search" src={`${URL_MEDIA}images/search.svg`} />
        }
        className="search-bar"
        placeholder="Search"
        onSearch={value => console.log(value)}
      />
      <div className="online-contact">
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Shivani Macharla</h3>
        </div>
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Rajesh Kondra</h3>
        </div>
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Manvita Syreddy</h3>
        </div>
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Chandu Chowdary</h3>
        </div>
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Venkat Shiva</h3>
        </div>
        <div className="contact-item">
          <div className="user">
            <Avatar src={`${URL_MEDIA}images/user.svg`} />
          </div>
          <h3>Dheeraj Rao Vulishe</h3>
        </div>
      </div>
    </Sider>
  );
};

export default Contacts;
