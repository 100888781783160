import React from "react";
import { Menu, Layout, Badge } from "antd";
import "./index.scss";
// ui
import { URL_MEDIA } from "../../../../config";

const Footer = () => {
  return (
    <Layout.Footer className="footer">
      <Menu
        mode="horizontal"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        // style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item key="1">
          {/* eslint-disable-next-line */}
          <a href="/dashboard" className="messages">
            <div className="nav-item">
              <Badge count={25}>
                <img className="ic-bar" src={`${URL_MEDIA}images/home-a.svg`} />
              </Badge>
              <span className="title">My Feed</span>
            </div>
          </a>
        </Menu.Item>
        <Menu.Item key="2">
          <a href="/" className="messages">
            {/* eslint-disable-next-line */}
            <div className="nav-item">
              <Badge count={8}>
                <img
                  src={`${URL_MEDIA}images/msg.svg`}
                  alt="chat"
                  className="ic-bar"
                />
              </Badge>
              <span className="title">Chat</span>
            </div>
          </a>
        </Menu.Item>
        <Menu.Item key="3">
          <a href="/" className="messages">
            {/* eslint-disable-next-line */}
            <div className="nav-item">
              <Badge count={2}>
                <img
                  src={`${URL_MEDIA}images/bell.svg`}
                  alt="notification"
                  className="ic-bar"
                />
              </Badge>
              <span className="title">Notifications</span>
            </div>
          </a>
        </Menu.Item>
        <Menu.Item key="4">
          <a href="/menu" className="messages">
            {/* eslint-disable-next-line */}
            <div className="nav-item">
              <Badge count={2}>
                <img
                  src={`${URL_MEDIA}images/menu.svg`}
                  alt="notification"
                  className="ic-bar"
                />
              </Badge>
              <span className="title">Menu</span>
            </div>
          </a>
        </Menu.Item>
      </Menu>
    </Layout.Footer>
  );
};

export default Footer;
