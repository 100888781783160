import React from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { Layout, Menu } from "antd";

import Footer from "../Layouts/Footer";

import { URL_MEDIA } from "../../../config";
import Card from "../../../components/Card";

import "./index.scss";

const { Content } = Layout;

function AppMenu() {
  return (
    <div className="menu-page">
      <Layout>
        <Layout>
          <Content className="layout-content">
            <h1>Menu</h1>
            <Card className="menu-page-section">
              <Menu
                mode="inline"
                defaultSelectedKeys={["1"]}
                defaultOpenKeys={["sub1"]}
                style={{ height: "100%", borderRight: 0 }}
              >
                <Menu.Item key="2">
                  <div className="nav-item">
                    {/* eslint-disable-next-line */}
                    <img
                      className="ic-bar"
                      src={`${URL_MEDIA}images/lib.svg`}
                    />

                    <span className="title">Library</span>
                    {/* <Badge count={25} style={{ background: "#3E7BFA" }} /> */}
                  </div>
                </Menu.Item>
                <Menu.Item key="3">
                  <div className="nav-item">
                    {/* eslint-disable-next-line */}
                    <img
                      className="ic-bar"
                      src={`${URL_MEDIA}images/explore.svg`}
                    />

                    <span className="title">Explore</span>
                    {/* <Badge count={25} style={{ background: "#3E7BFA" }} /> */}
                  </div>
                </Menu.Item>
              </Menu>
            </Card>
            <Card className="menu-page-section">
              <div className="my-classes">
                <div className="my-title">
                  <h3>MY CLASSES</h3>
                  <a href="/" className="btn-add">
                    <IoIosAddCircleOutline /> Add
                  </a>
                </div>
                <div className="list-classes">
                  <a href="/" className="class-item">
                    Trigonometry <span className="dot">&#183;</span>{" "}
                    <span> Class X-B</span>
                  </a>
                  <a href="/" className="class-item">
                    Arithmetics <span className="dot">&#183;</span>{" "}
                    <span> Class X-B</span>
                  </a>
                  <a href="/" className="class-item">
                    Problem Solving <span className="dot">&#183;</span>{" "}
                    <span> Class X-B</span>
                  </a>

                  <a href="/" className="show-more">
                    5 more classes
                  </a>
                </div>
              </div>
            </Card>

            <Card className="menu-page-section">
              <div className="my-classes">
                <div className="my-title">
                  <h3>MY GROUPS</h3>
                  <a href="/" className="btn-add">
                    <IoIosAddCircleOutline /> Add
                  </a>
                </div>
                <div className="list-classes">
                  <a href="/" className="class-item">
                    School Teachers in Bangalore
                  </a>
                  <a href="/" className="class-item">
                    Maths Teachers
                  </a>
                  <a href="/" className="class-item">
                    Teaching Methodology
                  </a>

                  <a href="/" className="show-more">
                    2 more classes
                  </a>
                </div>
              </div>
            </Card>
          </Content>
          <Footer className="home-page-footer" />
        </Layout>
      </Layout>
    </div>
  );
}

export default AppMenu;
