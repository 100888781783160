/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import moment from "moment";
// icons
import { RiUserAddLine } from "react-icons/ri";
// ui
import { Modal, Button, Avatar } from "antd";
import { URL_MEDIA } from "../../config";

const ViewCommented = props => {
  const { comments, isShow, onShow } = props;

  return (
    <Modal
      wrapClassName="show-bg"
      className="view-liked"
      title={false}
      closable={false}
      centered
      footer={false}
      visible={isShow}
      onOk={() => onShow(false)}
      onCancel={() => onShow(false)}
    >
      <div className="title">
        <h3>
          People who comments your post{" "}
          <span>{comments ? comments.length : 0}</span>
        </h3>
        <p>
          Last reaction was{" "}
          {comments.length > 0 &&
            moment(
              moment.utc(comments[comments.length - 1].updatedAt).local()
            ).toNow(true)}{" "}
          ago
        </p>
      </div>
      <div className="list-liked">
        {comments &&
          comments.map((item, i) => (
            <div key={i} className="liked-item">
              <div className="user">
                <Avatar
                  src={
                    item.avatar
                      ? item.avatar.location
                      : `${URL_MEDIA}images/user.svg`
                  }
                />
              </div>
              <h3>
                {item.user && item.user.first_name ? item.user.first_name : ""}{" "}
                {item.user && item.user.last_name ? item.user.last_name : ""}
              </h3>
              <Button type="primary" icon={<RiUserAddLine />}>
                Connect
              </Button>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default ViewCommented;
