/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./index.scss";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
// actions
import { signupInfoAndRelationship } from "../../../../redux/actions/users";
// ui
import {
  Form,
  Input,
  Select,
  Card,
  Row,
  Col,
  Button,
  Breadcrumb,
  message,
  Spin
} from "antd";
import { AiOutlineRight, AiOutlinePlus } from "react-icons/ai";

const { Option } = Select;

const DetailForm = props => {
  const [total, setTotal] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const age = moment().diff(props.form1.birthdayStr, "years");
  // let age = 24;
  const isLegal = age < 14;

  useEffect(() => {
    if (props.isRegister === true) {
      setLoading(false);
      localStorage.setItem("_token_club", props.user._token);
      message.success("Sign up new account successfully!");
      history.push("/dashboard");
    }
  }, [props.isRegister, history, props.user]);

  useEffect(() => {
    if (props.msgErr && props.msgErr !== "" && props.status === "sign-up") {
      setLoading(false);
      message.error(props.msgErr);
      history.push("/");
    }
  }, [props.msgErr, history, props.status]);

  const renderTitle = () => {
    if (props.role === "teacher") {
      return "Teacher";
    } else if (props.role === "parent") {
      return "Parent";
    } else {
      return "Student";
    }
  };

  const onSkipForm = async () => {
    console.log("form1: ", props.form1);

    if (isLegal && props.role === "student") {
      try {
        const values = await form.validateFields();
        console.log("Success:", values);
        props.setForm2(values);
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    } else {
      let fd = new FormData();
      fd.append("email", props.userInitCreate.email);
      fd.append("role", props.userInitCreate.role);
      fd.append("googleId", props.userInitCreate.googleId);
      fd.append("accessToken", props.userInitCreate.accessToken);
      fd.append("tokenId", props.userInitCreate.tokenId);
      fd.append("first_name", props.form1.first_name);
      fd.append("last_name", props.form1.last_name);
      fd.append("birthday", props.form1.birthdayStr);
      fd.append("gender", props.form1.gender);
      fd.append("about_you", props.form1.about_you);
      if (props.form1.fileResize) {
        fd.append("avatar", props.form1.fileResize);
      }

      setLoading(true);
      await props.signupInfoAndRelationship(fd);
    }
  };

  const onFinish = async values => {
    // check validate
    try {
      let checked = await form.validateFields([
        "email_0",
        "full_name_0",
        "relationship_0"
      ]);

      console.log(checked);

      let fd = new FormData();
      fd.append("email", props.userInitCreate.email);
      fd.append("role", props.userInitCreate.role);
      fd.append("googleId", props.userInitCreate.googleId);
      fd.append("accessToken", props.userInitCreate.accessToken);
      fd.append("tokenId", props.userInitCreate.tokenId);
      fd.append("first_name", props.form1.first_name);
      fd.append("last_name", props.form1.last_name);
      fd.append("birthday", props.form1.birthdayStr);
      fd.append("gender", props.form1.gender);
      fd.append("about_you", props.form1.about_you);
      if (props.form1.fileResize) {
        fd.append("avatar", props.form1.fileResize);
      }

      if (values) {
        fd.append("relationships", JSON.stringify(values));
      }
      setLoading(true);
      await props.signupInfoAndRelationship(fd);
    } catch (error) {
      console.log(error);
    }
  };

  const renderNode = () => {
    let results = [];
    for (let i = 0; i < total; i++) {
      results.push(
        <Row gutter={24} key={i}>
          <Col md={8} xs={24}>
            <Form.Item
              name={`full_name_${i}`}
              label="Full Name"
              rules={[
                {
                  required: i === 0 ? true : false,
                  message: "Please input name of parent!"
                }
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              name={`email_${i}`}
              label="Email"
              rules={[
                {
                  type: "email",
                  required: i === 0 ? true : false,
                  message: "Please input email of parent!"
                }
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            {props.role === "student" ? (
              <Form.Item
                name={`relationship_${i}`}
                label="Relationship"
                rules={[
                  {
                    required: i === 0 ? true : false,
                    message: "Please select relationship!"
                  }
                ]}
              >
                <Select placeholder="Relationship">
                  <Option value="mother">Mother</Option>
                  <Option value="father">Father</Option>
                  <Option value="guardian">Guardian</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name={`relationship_${i}`}
                label="Relationship"
                rules={[
                  {
                    required: i === 0 ? true : false,
                    message: "Please select relationship!"
                  }
                ]}
              >
                <Select placeholder="Relationship">
                  <Option value="son">Son</Option>
                  <Option value="daughter">Daughter</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
      );
    }

    return results;
  };

  return (
    <Spin spinning={isLoading}>
      <div className="detail-form">
        <Card
          title={
            <Breadcrumb separator={<AiOutlineRight />}>
              <Breadcrumb.Item href={`/auth/${props.role}`}>
                Create {renderTitle()} account
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {props.role === "student" ? "Parent" : "Children"} details
              </Breadcrumb.Item>
            </Breadcrumb>
          }
        >
          <Form onFinish={onFinish} layout="vertical" form={form}>
            <div className="content">
              <h3>
                {props.role === "parent"
                  ? "My Children Details"
                  : props.role === "student"
                  ? "My Parent Details"
                  : "Are you also a Parent?"}
              </h3>
              <h5>
                {props.role === "student"
                  ? `Add you parent details for your account safety, you can also skip this step.`
                  : `Add you children details and get complete control of their activity in the platform`}
              </h5>
              <div className="form-content">
                {renderNode()}
                <div className="add-new">
                  <Button
                    onClick={() => setTotal(total + 1)}
                    type="link"
                    icon={<AiOutlinePlus />}
                  >
                    {props.role === "student"
                      ? "Add another parent/guardian"
                      : "Add another child"}
                  </Button>
                </div>
              </div>
              <div className="actions">
                <Button type="primary" htmlType="submit">
                  Save and continue
                </Button>
                {!(isLegal && props.role === "student") && (
                  <Button
                    type="default"
                    className="btn-skip"
                    onClick={onSkipForm}
                  >
                    Skip
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Card>
      </div>
    </Spin>
  );
};

const mapStateToProps = store => {
  return {
    userInitCreate: store.users.userInitCreate,
    isRegister: store.users.isRegister,
    user: store.users.user,
    msgErr: store.users.msgErr,
    status: store.users.status
  };
};

const mapDispatchToProps = {
  signupInfoAndRelationship
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailForm);
