import React, { Component } from "react";
import {
  Activity,
  CommentField,
  CommentList,
  FlatFeed,
  LikeButton,
  NotificationDropdown,
  StatusUpdateForm,
  StreamApp
} from "react-activity-feed";
import "react-activity-feed/dist/index.css";

import { GETSTREAM_APP_ID, GETSTREAM_APP_KEY } from "config";


class FeedsGetStream extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          width: "800px",
          margin: "30px auto",
          background: "#f8f8f8",
          padding: "24px"
        }}
      >
        <StreamApp
          apiKey={GETSTREAM_APP_KEY}
          appId={GETSTREAM_APP_ID}
          token={null}
        >
          <NotificationDropdown notify />
          <StatusUpdateForm
            feedGroup="timeline"
            userId="user-one"
          // doRequest={e => {
          //   console.log(e);
          // }}
          />
          <FlatFeed
            options={{ reactions: { recent: true } }}
            notify
            Activity={props => (
              <Activity
                onClickHashtag={word => alert(`clicked on ${word}`)}
                {...props}
                Footer={() => (
                  <div style={{ padding: "8px 16px" }}>
                    <LikeButton {...props} />
                    <CommentField
                      // image="https://randomuser.me/api/portraits/men/91.jpg"
                      activity={props.activity}
                      onAddReaction={props.onAddReaction}
                    />
                    {/* <CommentField
                      image="https://randomuser.me/api/portraits/men/91.jpg"
                      // onAddReaction={e => alert("Click mention!" + e)}
                      onAddReaction={props.onAddReaction}
                    /> */}
                    <CommentList activityId={props.activity.id} />
                  </div>
                )}
              />
            )}
          />
        </StreamApp>
      </div>
    );
  }
}

export default FeedsGetStream;
