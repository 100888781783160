/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./index.scss";
import ReactPlayer from "react-player";
import moment from "moment";
import { URL_MEDIA } from "../../config";
import { EditorState, ContentState, convertToRaw, convertFromRaw } from "draft-js";
// icons
import { AiOutlineWarning, AiOutlineDownload } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
import { GrDocumentText, GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
import { FaRegFileExcel } from "react-icons/fa";
// ui
import { Button, Col, Dropdown, Menu, message, Row, Skeleton } from "antd";
import ViewImage from "../ViewImage";
import ViewVideo from "../ViewVideo";
import EditPost from "../EditPost";
import Modal from "antd/lib/modal/Modal";
import AddComment from "../AddComment";
import ViewLiked from "../ViewLiked";
import ViewComments from "../ViewComments";
import ViewCommented from "../ViewCommented";
import { useHistory } from "react-router-dom";
import Linkify from 'linkifyjs/react';
import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
hashtag(linkify);

const linkifyOptions = (history) => ({
  attributes: function (string, type) {
    return {
      onClick: (event) => {
        if (type === 'hashtag') {
          event.preventDefault();
          history.push(`/dashboard/hashtag${string}`)
        }
      }
    }
  },
  className: function (string, type) {
    let className = 'linkified'
    if (type === 'hashtag') {
      className = "hashtag";
    }
    return className;
  },
  defaultProtocol: 'http',
  formatHref: function (string, type) {
    if (type === 'hashtag') {
      string = "/dashboard/hashtag" + string;
    }
    return string;
  },
  tagName: {
    hashtag: 'span'
  },
  target: {
    url: '_blank'
  },
});


const ActivityItem = props => {
  const {
    time,
    user: { first_name, last_name, avatar, _id, role },
    images,
    attachments,
    videos,
    id,
    likes,
    comments
  } = props.item;
  const { onEditActivity, key } = props;
  const history = useHistory()

  const [isViewImage, setViewImage] = useState(false);
  const [isViewVideo, setViewVideo] = useState(false);
  const [dataImage, setDataImage] = useState(null);
  const [dataVideo, setDataVideo] = useState(null);
  const [isShowConfirm, setShowPopupConfirm] = useState(false);
  const [isShowLiked, setShowLiked] = useState(false);
  const [isShowCommented, setShowCommented] = useState(false);

  const onLikePopup = id => {
    props.onLike(id);
  };

  const onViewImage = (image, index) => {
    console.log("index: ", index);
    setDataImage(image);
    setViewImage(true);
  };

  const handleAddComment = contentHtml => {
    props.onAddComment(id, { contentHtml });
  };

  const onViewVideo = (video, index) => {
    console.log("index: ", index);
    setDataVideo(video);
    setViewVideo(true);
  };

  const confirm = () => {
    // message.success("Click on Yes");
    props.onDeleteActivity(props.item.id);
    setShowPopupConfirm(false);
  };

  const renderIconTypeFile = name => {
    let arr = name.split(".");
    let ext = arr[arr.length - 1].toLowerCase();
    if (ext === "xlsx") {
      return <FaRegFileExcel />;
    } else if (ext === "pdf") {
      return <GrDocumentPdf />;
    } else if (ext === "csv") {
      return <GrDocumentCsv />;
    } else {
      return <GrDocumentText />;
    }
  };

  const onFlagPost = () => {
    message.success("Successfully reported to Cultural Club for Review!");
  };

  const checkMyLiked = likes => {
    let checked = likes.filter(el => String(el._id) === String(props.user._id));

    if (checked.length > 0) {
      return (
        <Button
          type="link"
          onClick={() => props.onLike(id)}
          className="me-liked"
        >
          {/* <AiTwotoneLike /> Like */}
          <img src={`${URL_MEDIA}images/Liked.svg`} alt="" /> Like
        </Button>
      );
    } else {
      return (
        <Button type="link" onClick={() => props.onLike(id)}>
          {/* <AiOutlineLike /> Like */}
          <img src={`${URL_MEDIA}images/Like.svg`} alt="" /> Like
        </Button>
      );
    }
  };

  const renderLiked = likes => {
    let checked = likes.filter(el => String(el._id) === String(props.user._id));

    if (checked.length > 0) {
      if (likes.length > 1) {
        return `You and ${likes.length - 1} others`;
      } else {
        return `You`;
      }
    } else {
      return `${likes.length > 0 ? likes.length : ""} Like${
        likes.length > 1 ? "s" : ""
        }`;
    }
  };

  const renderLikesAndComments = (likes, comments) => {
    if (likes.length === 0 && comments.length === 0) {
      return "";
    } else {
      return (
        <div className="view-info">
          {likes.length > 0 && (
            <div className="view-likes" onClick={() => setShowLiked(true)}>
              {/* <span className="ic-liked"> */}
              <img src={`${URL_MEDIA}images/Like-count.svg`} alt="" />
              {/* </span> */}
              {renderLiked(likes)}
            </div>
          )}

          {comments.length > 0 && (
            <div
              className="view-commented"
              onClick={() => setShowCommented(true)}
            >
              <span className="ic-commented">
                <img src={`${URL_MEDIA}images/cmt.svg`} alt="" />
              </span>
              {comments && comments.length ? comments.length : ""} Comment
              {comments && comments.length > 1 ? "s" : ""}
            </div>
          )}
        </div>
      );
    }
  };


  return (
    <Skeleton loading={props.item == null} active avatar>
      {props.activityId === id ? (
        <EditPost
          activityId={props.item.id}
          text={props.item.content}
          contentText={props.item.contentText}
          editorToRaw={props.item.editorToRaw}
          images={props.item.images}
          videos={props.item.videos}
          attachments={props.item.attachments}
          onUpdateActivity={props.onUpdateActivity}
          mentionUsers={props.mentionUsers}
        />
      ) : (
          <div className="activity-item">
            <div className="activity-hd">
              <Row gutter={24}>
                <Col md={16}>
                  <div className="user-info">
                    <div className="avt">
                      <img
                        src={
                          avatar ? avatar.location : `${URL_MEDIA}images/user.svg`
                        }
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h3>
                        {first_name ? first_name : ""}{" "}
                        {last_name ? last_name : ""}
                      </h3>
                      <p>{role ? role : ""}</p>
                      {/* <p>Science Teacher · Bangalore, KA</p> */}
                    </div>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="time-actions">
                    <p className="time">
                      {moment(moment.utc(time).local()).toNow(true)} ago
                  </p>

                    <Dropdown
                      placement="bottomRight"
                      overlay={
                        <Menu className="menu-post">
                          {props.user && String(props.user._id) === _id && (
                            <Menu.Item key="0" onClick={() => onEditActivity(id)}>
                              <FiEdit2 /> Edit
                            </Menu.Item>
                          )}

                          {props.user && String(props.user._id) === _id && (
                            <Menu.Item
                              key="1"
                              onClick={() => setShowPopupConfirm(true)}
                            >
                              <RiDeleteBin6Line /> Delete
                            </Menu.Item>
                          )}

                          {props.user && String(props.user._id) !== _id && (
                            <Menu.Item key="0" onClick={() => onFlagPost()}>
                              <AiOutlineWarning /> Flag as inappropriate
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        type="link"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <img src={`${URL_MEDIA}images/dots.svg`} alt="" />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="activity-content">

              <Linkify options={linkifyOptions(history)}>
                {props.item.contentText}
              </Linkify>

              {/* {urlsViewed.map((url, i) => <p key={i}>{url}</p>)} */}
            </div>

            {images && images.length > 0 && (
              <div className="activity-images">
                {images.map((img, k) => (
                  <img
                    key={k}
                    src={img.location}
                    alt=""
                    onClick={() => onViewImage(img, k)}
                  />
                ))}
              </div>
            )}

            {videos &&
              videos.length > 0 &&
              videos.map((vid, v) => (
                <div
                  className="video-item"
                  key={v}
                  onClick={() => onViewVideo(vid, v)}
                >
                  <ReactPlayer className="view-video" url={vid.location} />
                  <Button type="link">
                    <img src={`${URL_MEDIA}images/play.svg`} alt="" />
                  </Button>
                </div>
              ))}

            {attachments && attachments.length > 0 && (
              <div className="view-attachments-uploading">
                {attachments.map((file, f) => (
                  <div key={f} className="file-item">
                    <div className="file-type-icon">
                      {renderIconTypeFile(file.originalname)}
                    </div>
                    <div className="file-info">
                      <h3>{file.originalname}</h3>
                      <p>{(file.size / 1024).toFixed(2)} KB</p>
                    </div>
                    <div className="act-down">
                      <Button
                        type="link"
                        href={file.location}
                        download
                        target="_blank"
                      >
                        <AiOutlineDownload />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            )}

            <div className="act-actions">
              {renderLikesAndComments(likes, comments)}
              <Row gutter={24}>
                <Col md={12}>
                  <div className="action-item">{checkMyLiked(likes)}</div>
                </Col>
                <Col md={12}>
                  <div className="action-item">
                    <Button type="link">
                      <img src={`${URL_MEDIA}images/msg1.svg`} alt="" /> Comment
                  </Button>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="comments">
              <AddComment
                key={key}
                user={props.user}
                onComment={handleAddComment}
                mentionUsers={props.mentionUsers}
              />
            </div>

            {comments.length > 0 && (
              <ViewComments
                comments={comments}
                onLikeComment={props.onLikeComment}
                user={props.user}
                onDeleteComment={props.onDeleteComment}
                onUpdateComment={props.onUpdateComment}
                mentionUsers={props.mentionUsers}
              />
            )}
          </div>
        )}

      <ViewLiked isShow={isShowLiked} onShow={setShowLiked} likes={likes} />

      <ViewCommented
        isShow={isShowCommented}
        onShow={setShowCommented}
        comments={comments}
      />

      <ViewImage
        isView={isViewImage}
        onView={setViewImage}
        dataImage={dataImage}
        user={props.user}
        item={props.item}
        handleAddComment={handleAddComment}
        onLikePopup={onLikePopup}
        onDeleteComment={props.onDeleteComment}
        onUpdateComment={props.onUpdateComment}
        onLike={props.onLike}
      />

      <ViewVideo
        isView={isViewVideo}
        onView={setViewVideo}
        dataVideo={dataVideo}
        user={props.user}
        item={props.item}
        handleAddComment={handleAddComment}
        onLikePopup={onLikePopup}
        onDeleteComment={props.onDeleteComment}
        onUpdateComment={props.onUpdateComment}
        onLike={props.onLike}
      />

      <Modal
        title="Are you sure delete?"
        footer={false}
        centered
        visible={isShowConfirm}
        onOk={() => setShowPopupConfirm(false)}
        onCancel={() => setShowPopupConfirm(false)}
      >
        <div className="actions-confirm">
          <Button type="default" onClick={() => setShowPopupConfirm(false)}>
            Cancel
          </Button>
          <Button type="primary" danger onClick={() => confirm()}>
            Delete
          </Button>
        </div>
      </Modal>
    </Skeleton>
  );
};

export default ActivityItem;
