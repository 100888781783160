/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
import { URL_MEDIA } from "../../config";
import { Picker } from "emoji-mart";

// editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

// icons
import { AiOutlineWarning } from "react-icons/ai";
import { LoadingOutlined } from "@ant-design/icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
// ui
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Spin,
  message,
  Input,
  Modal
} from "antd";
import ViewLiked from "../ViewLiked";
import htmlToDraft from "html-to-draftjs";

const { TextArea } = Input;

const ViewComments = props => {
  const [isLoadMore, setLoadMore] = useState(false);
  const [showTotal, setShowTotal] = useState(2);
  const [isShowConfirm, setShowPopupConfirm] = useState(false);
  const [commentIdDelete, setCommentIdDelete] = useState(null);
  const [isViewEditId, setViewEdit] = useState(false);
  const [contentEdit, setContentEdit] = useState("");
  const [isShowEmoji, setShowEmoji] = useState(false);
  const [isShowLiked, setShowLiked] = useState(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const { comments, mentionUsers } = props;

  const onViewMoreComments = () => {
    setLoadMore(true);
    setTimeout(() => {
      setShowTotal(showTotal + 5);
      setLoadMore(false);
    }, 300);
  };

  const addEmoji = e => {
    let emoji = e.native;
    setContentEdit(contentEdit + emoji);
    setShowEmoji(false);
  };

  const checkMyLiked = (likes, commentId) => {
    let checked = likes.filter(el => String(el._id) === String(props.user._id));

    if (checked.length > 0) {
      return (
        <Button
          type="link"
          onClick={() => props.onLikeComment(commentId)}
          className="me-liked"
        >
          <img src={`${URL_MEDIA}images/Liked.svg`} alt="" /> Like
        </Button>
      );
    } else {
      return (
        <Button type="link" onClick={() => props.onLikeComment(commentId)}>
          <img src={`${URL_MEDIA}images/Like.svg`} alt="" /> Like
        </Button>
      );
    }
  };

  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };

  const onViewEditComment = item => {
    setViewEdit(item._id);
    setContentEdit(item.content);

    const blocksFromHtml = htmlToDraft(item.contentHtml);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const _editorState = EditorState.createWithContent(contentState);

    console.log("_editorState: ", _editorState);

    setEditorState(_editorState);
  };

  const onSubmit = async () => {
    console.log("isViewEditId: ", isViewEditId);
    console.log("editorState: ", editorState);

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const hashtagConfig = {
      trigger: "#",
      separator: " "
    };

    const { blocks } = rawContentState;
    console.log("text: ", blocks[0].text.trim());
    rawContentState.blocks[0].text = blocks[0].text.trim();
    console.log(rawContentState);
    const markup = draftToHtml(rawContentState, hashtagConfig);

    if (blocks[0].text.trim() !== "") {
      await props.onUpdateComment(isViewEditId, { contentHtml: markup });
      await setEditorState(EditorState.createEmpty());
      await setViewEdit(null);
    }

    // if (contentEdit.trim() !== "") {
    //   await props.onUpdateComment(isViewEditId, { content: contentEdit });
    //   await setContentEdit("");
    //   await setViewEdit(null);
    // }
  };

  const onFlagPost = () => {
    message.success("Successfully reported to Cultural Club for Review!");
  };

  const confirm = () => {
    // message.success("Click on Yes");
    props.onDeleteComment(commentIdDelete);
    setShowPopupConfirm(false);
  };

  const showMax2Comments = () => {
    let count = 0;
    let results = [];
    // let start =
    //   comments.length > 2  ? comments.length - 2 : 0;

    for (let i = comments.length - 1; i > -1; i--) {
      const item = comments[i];

      if (count < showTotal) {
        results.push(
          <div key={i} className="comment-item">
            <div className="cmt-header">
              <Row gutter={24}>
                <Col md={12}>
                  <div className="user">
                    <div className="avt">
                      <img
                        src={
                          item.user && item.user.avatar
                            ? item.user.avatar.location
                            : `${URL_MEDIA}images/user.svg`
                        }
                        alt=""
                      />
                    </div>
                    <h3>
                      {item.user ? item.user.first_name : ""}{" "}
                      {item.user ? item.user.last_name : ""}
                    </h3>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="time-actions">
                    <p className="time">
                      {moment(moment.utc(item.createdAt).local()).toNow(true)}{" "}
                      ago
                    </p>

                    <Dropdown
                      placement="bottomRight"
                      overlay={
                        <Menu className="menu-post">
                          {props.user &&
                            String(props.user._id) === item.user._id && (
                              <Menu.Item
                                key="0"
                                onClick={() => onViewEditComment(item)}
                              >
                                <FiEdit2 /> Edit
                              </Menu.Item>
                            )}

                          {props.user &&
                            String(props.user._id) === item.user._id && (
                              <Menu.Item
                                key="1"
                                onClick={() => {
                                  setShowPopupConfirm(true);
                                  setCommentIdDelete(item._id);
                                }}
                              >
                                <RiDeleteBin6Line /> Delete
                              </Menu.Item>
                            )}

                          {props.user &&
                            String(props.user._id) !== item.user._id && (
                              <Menu.Item key="0" onClick={() => onFlagPost()}>
                                <AiOutlineWarning /> Flag as inappropriate
                              </Menu.Item>
                            )}
                        </Menu>
                      }
                      trigger={["click"]}
                    >
                      <Button
                        type="link"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        <img src={`${URL_MEDIA}images/dots.svg`} alt="" />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
            </div>

            {isViewEditId === item._id ? (
              <div className="view-edit-cmt">
                <div className="edit-input">
                  {/* <TextArea
                    onChange={e => setContentEdit(e.target.value)}
                    value={contentEdit}
                    // onKeyPress={event => {
                    //   if (event.key === "Enter") {
                    //     onSubmit();
                    //   }
                    // }}
                    autoSize={{ minRows: 3, maxRows: 10 }}
                  />
                  <Button
                    className="btn-emoji"
                    type="link"
                    onClick={() => setShowEmoji(!isShowEmoji)}
                  >
                    <img src={`${URL_MEDIA}images/smile.svg`} alt="" />
                  </Button> */}

                  <Editor
                    placeholder="Write a Comment"
                    editorState={editorState}
                    wrapperClassName="post-wrapper"
                    editorClassName="post-editor"
                    onEditorStateChange={onEditorStateChange}
                    mention={{
                      separator: " ",
                      trigger: "@",
                      suggestions: mentionUsers
                    }}
                    locale={{}}
                    // hashtag={{
                    //   separator: " ",
                    //   trigger: "#"
                    // }}
                    toolbar={{
                      options: ["emoji"],
                      emoji: {
                        className: "emoji-option-custom",
                        popupClassName: "emoji-popup-custom"
                      }
                    }}
                  />
                </div>

                {isShowEmoji && (
                  <div className="emojis">
                    <Picker onSelect={addEmoji} set="twitter" theme="light" />
                  </div>
                )}

                <div className="actions-edit">
                  <Button type="primary" onClick={() => onSubmit()}>
                    UPDATE
                  </Button>
                </div>
              </div>
            ) : (
              <div className="cmt-content">
                {/* <Linkify tagName="div" options={linkifyOptions}>
                  {item.content ? item.content : ""}
                </Linkify> */}

                <div
                  className="content-html"
                  dangerouslySetInnerHTML={{
                    __html: item.contentHtml ? item.contentHtml : ""
                  }}
                />

                {item.likes.length > 0 && (
                  <span
                    className="liked"
                    onClick={() => setShowLiked(item._id)}
                  >
                    <img src={`${URL_MEDIA}images/Like-count.svg`} alt="" />
                    {item.likes.length > 0 ? item.likes.length : ""}
                  </span>
                )}
              </div>
            )}
            {isViewEditId !== item._id && (
              <div className="cmt-actions">
                <div className="action-item">
                  {checkMyLiked(item.likes, item._id)}
                </div>
              </div>
            )}

            <ViewLiked
              isShow={isShowLiked === item._id ? true : false}
              onShow={setShowLiked}
              likes={item.likes}
            />
          </div>
        );
      }
      count++;
    }

    if (comments.length > showTotal) {
      results.push(
        <div key={comments.length} className="view-mores-cmt">
          <Button type="link" onClick={() => onViewMoreComments()}>
            View more comments{" "}
            <Spin
              spinning={isLoadMore}
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          </Button>
        </div>
      );
    }

    return results;
  };

  return (
    <div className="view-comments">
      {showMax2Comments()}
      <Modal
        title="Are you sure delete?"
        footer={false}
        centered
        visible={isShowConfirm}
        onOk={() => setShowPopupConfirm(false)}
        onCancel={() => setShowPopupConfirm(false)}
      >
        <div className="actions-confirm">
          <Button type="default" onClick={() => setShowPopupConfirm(false)}>
            Cancel
          </Button>
          <Button type="primary" danger onClick={() => confirm()}>
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewComments;
