/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import { connect } from "react-redux";
import { URL_MEDIA } from "../../../config";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
// import Editor from "draft-js-plugins-editor";
import draftToHtml from "draftjs-to-html";
import createLinkifyPlugin from "draft-js-linkify-plugin";
// actions
import {
  createActivityOfFeed,
  getActiviesOfFeed,
  deleteActivity,
  likeActivity,
  updateActivity,
  commentActivity,
  likeCommentOfActivity,
  deleteCommentOfActivity,
  updateCommentOfActivity
} from "../../../redux/actions/feeds";
import { getAllUsersMention } from "../../../redux/actions/users";
// icons
import { TiDelete } from "react-icons/ti";
import { GrDocumentText, GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
import { FaRegFileExcel, FaVideo } from "react-icons/fa";
// ui
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Upload
} from "antd";
import ActivityItem from "../../../components/ActivityItem";

const linkifyPlugin = createLinkifyPlugin({
  target: "_blank",
  component: props => {
    const { contentState, ...rest } = props;
    return (
      <a
        className="post-link"
        {...rest}
        onClick={() => alert("link clicked `12")}
      />
    );
  }
});
const plugins = [linkifyPlugin];

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLessPost: true,
      isShowEmoji: false,
      text: "",
      html: "",
      contentHtml: "",
      viewedBy: "onClub",
      isLoading: false,
      images: [],
      imagesData: [],
      attachments: [],
      videos: [],
      activityId: null,
      isSpace: false,
      editorState: EditorState.createEmpty()
    };
    this.contentEditable = React.createRef();
    this.refEditor = React.createRef();
    this.wrapperRefPost = React.createRef();
    this.wrapperRefEmoji = React.createRef();
    this.addEmoji = this.addEmoji.bind(this);
    this.onChangeViewed = this.onChangeViewed.bind(this);
    this.onChangePost = this.onChangePost.bind(this);
    this.onShowPopupEmoji = this.onShowPopupEmoji.bind(this);
    this.onSubmitPost = this.onSubmitPost.bind(this);
    this.onBeforeUploadImages = this.onBeforeUploadImages.bind(this);
    this.onRemoveImage = this.onRemoveImage.bind(this);
    this.onBeforeUploadFiles = this.onBeforeUploadFiles.bind(this);
    this.renderIconTypeFile = this.renderIconTypeFile.bind(this);
    this.onRemoveFiles = this.onRemoveFiles.bind(this);
    this.onRemoveVideos = this.onRemoveVideos.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.onLike = this.onLike.bind(this);
    this.onDeleteActivity = this.onDeleteActivity.bind(this);
    this.onUpdateActivity = this.onUpdateActivity.bind(this);
    this.onEditActivity = this.onEditActivity.bind(this);
    this.onAddComment = this.onAddComment.bind(this);
    this.renderTextRadio = this.renderTextRadio.bind(this);
    this.onLikeComment = this.onLikeComment.bind(this);
    this.onUpdateComment = this.onUpdateComment.bind(this);
    this.onDeleteComment = this.onDeleteComment.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    this.props.getActiviesOfFeed();
    this.props.getAllUsersMention();
  }

  componentWillUnmount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  onEditorStateChange(editorState) {
    console.log("editorState: ", editorState);
    this.setState({
      editorState
    });

    // const rawContentState = convertToRaw(editorState.getCurrentContent());
    // const hashtagConfig = {
    //   trigger: "#",
    //   separator: " "
    // };

    // const markup = draftToHtml(rawContentState, hashtagConfig);

    // console.log("markup: ", markup);

    // this.setState({ editorState }, () => {
    //   const triggerRangeHttp = this.getTriggerRange("http://");
    //   const triggerRangeHttps = this.getTriggerRange("https://");
    //   console.log("triggerRangeHttp: ", triggerRangeHttp);
    //   console.log("triggerRangeHttps: ", triggerRangeHttps);
    // });
  }

  getTriggerRange(trigger) {
    const selection = window.getSelection();
    if (selection.rangeCount === 0) return null;
    const range = selection.getRangeAt(0);
    const text = range.startContainer.textContent.substring(
      0,
      range.startOffset
    );
    if (/s+$/.test(text)) return null;
    const index = text.lastIndexOf(trigger);
    if (index === -1) return null;

    let el = (
      <a href={text.substring(index)} target="_blank" className="post-link">
        {text.substring(index)}
      </a>
    );

    return {
      text: text.substring(index),
      start: index,
      end: range.startOffset,
      url: `/dashboard/${text.substring(index + 1)}`
    };
  }

  async onSubmitPost() {
    const { text, images, attachments, videos, editorState } = this.state;
    const contentState = editorState.getCurrentContent()
    const contentText = contentState.getPlainText('\u0001')
    const rawContentState = convertToRaw(contentState);


    this.setState({ isLoading: true });

    let fd = new FormData();
    fd.append("content", text.trim());
    fd.append("contentText", contentText);
    fd.append("editorToRaw", JSON.stringify(rawContentState));

    for (let i = 0; i < images.length; i++) {
      const img = images[i];
      fd.append("images", img);
    }

    for (let i = 0; i < attachments.length; i++) {
      const img = attachments[i];
      fd.append("attachments", img);
    }

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];
      fd.append("videos", video);
    }

    await this.props.createActivityOfFeed(fd);
    (await this.props.isCreatedActivity) === true
      ? message.success("Created activity successfully!")
      : message.error("Created activity failed!");
    (await this.props.isCreatedActivity) === true &&
      this.props.getActiviesOfFeed();
    await this.setState({
      isLoading: false,
      isShowLessPost: true,
      text: "",
      images: [],
      imagesData: [],
      attachments: [],
      videos: [],
      editorState: EditorState.createEmpty()
    });
  }

  async onUpdateComment(commentId, data) {
    await this.props.updateCommentOfActivity(commentId, data);
    (await this.props.isUpdatedCommentActivity) === true
      ? message.success("Updated comment")
      : message.error("Update comment failed!");
    (await this.props.isUpdatedCommentActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  async onDeleteComment(commentId) {
    await this.props.deleteCommentOfActivity(commentId);
    (await this.props.isDeletedCommentActivity) === true
      ? message.success("Deleted!")
      : message.error("Delete comment failed!");
    (await this.props.isDeletedCommentActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  renderTextRadio(user) {
    const { role } = user;
    if (role === "parent") {
      return "Parent";
    } else if (role === "student") {
      return "Student";
    } else {
      return "Teacher";
    }
  }

  handleClickOutside(event) {
    if (
      !(
        this.wrapperRefPost &&
        this.wrapperRefPost.current &&
        this.wrapperRefPost.current.contains(event.target)
      )
    ) {
      const { blocks } = convertToRaw(
        this.state.editorState.getCurrentContent(),
        {
          trigger: "#",
          separator: " ",
        }
      );

      if (blocks[0].text === "") {
        this.setState({ isShowLessPost: true, isShowEmoji: false });
      }
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  getExtension(filename) {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  }

  isVideo(filename) {
    var ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        // etc
        return true;
      default:
        return false;
    }
  }

  onBeforeUploadImages(image) {
    let checkTypeVideo = this.isVideo(image.name);
    if (checkTypeVideo) {
      const isLt25M = image.size / 1024 / 1024 < 25;
      if (!isLt25M) {
        message.error("Video must smaller than 25 MB!");
      } else {
        this.setState({ videos: [...this.state.videos, image] });
        return false;
      }
    } else {
      const isLt4M = image.size / 1024 / 1024 < 4;
      if (!isLt4M) {
        message.error("Image must smaller than 4 MB!");
      } else {
        this.setState({ images: [...this.state.images, image] });

        this.getBase64(image, imageUrl =>
          this.setState({
            imagesData: [...this.state.imagesData, imageUrl]
          })
        );

        return false;
      }
    }
  }

  onBeforeUploadFiles(file) {
    const isLt25M = file.size / 1024 / 1024 < 25;
    if (!isLt25M) {
      message.error("File must smaller than 25 MB!");
    } else {
      this.setState({ attachments: [...this.state.attachments, file] });
      return false;
    }
  }

  onEditActivity(activityId) {
    this.setState({ activityId });
  }

  onRemoveImage(index) {
    const { images, imagesData } = this.state;
    images.splice(index, 1);
    imagesData.splice(index, 1);
    this.setState({ imagesData, images });
  }

  onRemoveVideos(index) {
    const { videos } = this.state;
    videos.splice(index, 1);
    this.setState({ videos });
  }

  onRemoveFiles(index) {
    const { attachments } = this.state;
    attachments.splice(index, 1);
    this.setState({ attachments });
  }

  onChangeViewed(e) {
    this.setState({ viewedBy: e.target.value });
  }

  onShowPopupEmoji(isShowEmoji) {
    this.setState({ isShowEmoji });
  }

  onChangePost(isShowLessPost) {
    this.setState({ isShowLessPost });
  }

  handleChangeEditor(evt) {
    this.setState({ html: evt.target.value });
  }

  addEmoji(e) {
    let emoji = e.native;
    this.setState({
      text: this.state.text + emoji,
      isShowEmoji: false
    });
  }

  async onLike(activityId) {
    await this.props.likeActivity(activityId);
    (await this.props.isLikedActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  async onAddComment(activityId, data) {
    await this.props.commentActivity(activityId, data);
    (await this.props.isCommentActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  async onDeleteActivity(activityId) {
    console.log("activityId: ", activityId);
    await this.props.deleteActivity(activityId);
    (await this.props.isDeletedActivity) === true
      ? message.success("Deleted activity!")
      : message.error("Deleted activity failed!");
    (await this.props.isDeletedActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  async onUpdateActivity(activityId, data) {
    console.log(activityId, data);
    await this.props.updateActivity(activityId, data);
    (await this.props.isUpdatedActivity) === true
      ? message.success("Updated activity successfully!")
      : message.error("Updated activity failed!");
    (await this.props.isUpdatedActivity) === true &&
      this.setState({ activityId: null });
    (await this.props.isUpdatedActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  renderIconTypeFile(name) {
    let arr = name.split(".");
    let ext = arr[arr.length - 1].toLowerCase();
    if (ext === "xlsx") {
      return <FaRegFileExcel />;
    } else if (ext === "pdf") {
      return <GrDocumentPdf />;
    } else if (ext === "csv") {
      return <GrDocumentCsv />;
    } else {
      return <GrDocumentText />;
    }
  }

  checkText() {
    const {
      // text,
      images,
      videos,
      attachments,
      editorState
    } = this.state;

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const hashtagConfig = {
      trigger: "#",
      separator: " "
    };

    const markup = draftToHtml(rawContentState, hashtagConfig);

    if (markup && markup.trim() !== "") {
      return false;
    } else {
      if (images.length > 0 || videos.length > 0 || attachments.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  async onLikeComment(commentId) {
    await this.props.likeCommentOfActivity(commentId);
    (await this.props.isLikedCommentActivity) === true &&
      this.props.getActiviesOfFeed();
  }

  render() {
    const { user, activitiesFeed, mentionUsers } = this.props;
    const {
      isShowLessPost,
      viewedBy,
      isLoading,
      images,
      imagesData,
      attachments,
      videos,
      editorState
    } = this.state;

    return (
      <Spin spinning={isLoading}>
        <div className="feed-ctn">


          {isShowLessPost ? (
            <div className="post-activity-less">
              <div className="user-avt">
                <img
                  src={
                    user && user.avatar
                      ? user.avatar.location
                      : `${URL_MEDIA}images/user.svg`
                  }
                  alt=""
                />
              </div>
              <Input
                onFocus={() => this.onChangePost(false)}
                placeholder={`Share what’s on your mind with other ${
                  user ? user.role : ""
                  }s`}
              />
              <div className="act-icon" title="Images/Videos">
                <img src={`${URL_MEDIA}images/Image.svg`} alt="" />
              </div>
              <div className="act-icon" title="Files">
                <img src={`${URL_MEDIA}images/library.svg`} alt="" />
              </div>
            </div>
          ) : (
              <div className="post-activity-full" ref={this.wrapperRefPost}>
                <div className="post-top">
                  {/* <Editor
                  editorState={this.state.editorState}
                  onChange={this.onEditorStateChange}
                  plugins={plugins}
                  ref={element => {
                    this.editor = element;
                  }}
                /> */}

                  <Editor
                    placeholder={`Share what’s on your mind with other ${
                      user && user.role ? user.role : ""
                      }s`}
                    editorState={editorState}
                    wrapperClassName="post-wrapper"
                    editorClassName="post-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    // toolbarHidden
                    mention={{
                      separator: " ",
                      trigger: "@",
                      suggestions: mentionUsers,
                      tagName: "span"
                    }}
                    hashtag={{
                      separator: " ",
                      trigger: "#",
                      href: "xxx",
                      tagName: "span"
                    }}
                    toolbar={{
                      options: ["emoji"],
                      emoji: {
                        className: "emoji-option-custom",
                        popupClassName: "emoji-popup-custom"
                      }
                    }}
                    plugins={plugins}
                  />

                  {/* view images upload here */}
                  {imagesData.length > 0 && (
                    <div className="view-images-uploading">
                      {imagesData.map((url, i) => (
                        <div key={i} className="img-item">
                          <Button
                            type="link"
                            className="btn-remove"
                            onClick={() => this.onRemoveImage(i)}
                          >
                            <TiDelete />
                          </Button>
                          <img src={url} alt="" />
                        </div>
                      ))}
                    </div>
                  )}

                  {videos.length > 0 && (
                    <div className="view-attachments-uploading view-videos-uploading">
                      {videos.map((file, f) => (
                        <div key={f} className="file-item">
                          <div className="file-type-icon">
                            <FaVideo />
                          </div>
                          <div className="file-info">
                            <h3>{file.name}</h3>
                            <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                            <Button
                              type="link"
                              className="btn-remove"
                              onClick={() => this.onRemoveVideos(f)}
                            >
                              <TiDelete />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* view attachments upload */}
                  {attachments.length > 0 && (
                    <div className="view-attachments-uploading">
                      {attachments.map((file, f) => (
                        <div key={f} className="file-item">
                          <div className="file-type-icon">
                            {this.renderIconTypeFile(file.name)}
                          </div>
                          <div className="file-info">
                            <h3>{file.name}</h3>
                            <p>{(file.size / 1024).toFixed(2)} KB</p>
                            <Button
                              type="link"
                              className="btn-remove"
                              onClick={() => this.onRemoveFiles(f)}
                            >
                              <TiDelete />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="post-actions">
                    <div className="actions-left">
                      <Upload
                        disabled={images.length > 4 || attachments.length > 0}
                        beforeUpload={file => this.onBeforeUploadImages(file)}
                        accept=".png, .jpg, .jpeg, video/*"
                        showUploadList={false}
                      >
                        <div className="act-icon">
                          <img src={`${URL_MEDIA}images/img.svg`} alt="" />
                          <span>Add photo/video</span>
                        </div>
                      </Upload>

                      <Upload
                        disabled={
                          attachments.length > 4 ||
                          images.length > 0 ||
                          videos.length > 0
                        }
                        beforeUpload={file => this.onBeforeUploadFiles(file)}
                        accept="application/pdf,
                      .csv,
                      .xlsx,
                      .xls,
                      application/vnd.ms-excel
                      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                      application/msword,
                      application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        showUploadList={false}
                      >
                        <div className="act-icon">
                          <img src={`${URL_MEDIA}images/docs.svg`} alt="" />
                          {/* <MdImage /> */}
                          <span>Add Document</span>
                        </div>
                      </Upload>
                    </div>
                  </div>
                </div>
                <div className="post-bottom">
                  <Row gutter={24}>
                    <Col md={18}>
                      <div className="viewed-option">
                        <h3>This post can be viewed by</h3>
                        <Radio.Group
                          onChange={this.onChangeViewed}
                          value={viewedBy}
                        >
                          <Radio value="onClub">
                            Any {user && this.renderTextRadio(user)}
                          </Radio>
                          <Radio value="any">Only your Connections</Radio>
                        </Radio.Group>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="act-submit">
                        <Button
                          type="primary"
                          onClick={() => this.onSubmitPost()}
                          disabled={this.checkText()}
                        >
                          POST
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

          <div className="list-activities">
            {activitiesFeed && activitiesFeed.length > 0 ? (
              activitiesFeed.map((item, i) => (
                <ActivityItem
                  key={i}
                  item={item}
                  user={user}
                  activityId={this.state.activityId}
                  onLike={this.onLike}
                  onDeleteActivity={this.onDeleteActivity}
                  onUpdateActivity={this.onUpdateActivity}
                  onEditActivity={this.onEditActivity}
                  onAddComment={this.onAddComment}
                  onLikeComment={this.onLikeComment}
                  onDeleteComment={this.onDeleteComment}
                  onUpdateComment={this.onUpdateComment}
                  mentionUsers={mentionUsers}
                />
              ))
            ) : (
                <Empty key={0} />
              )}
          </div>

        </div>
      </Spin>
    );
  }
}

const mapStateToProps = store => {
  return {
    activityFeed: store.feeds.activityFeed,
    activitiesFeed: store.feeds.activitiesFeed,
    isCreatedActivity: store.feeds.isCreatedActivity,
    isLikedActivity: store.feeds.isLikedActivity,
    isCommentActivity: store.feeds.isCommentActivity,
    isDeletedActivity: store.feeds.isDeletedActivity,
    isUpdatedActivity: store.feeds.isUpdatedActivity,
    isLikedCommentActivity: store.feeds.isLikedCommentActivity,
    isDeletedCommentActivity: store.feeds.isDeletedCommentActivity,
    isUpdatedCommentActivity: store.feeds.isUpdatedCommentActivity,
    // user
    mentionUsers: store.users.mentionUsers,
    // error
    msgErr: store.feeds.msgErr
  };
};

const mapDispatchToProps = {
  createActivityOfFeed,
  getActiviesOfFeed,
  deleteActivity,
  likeActivity,
  updateActivity,
  commentActivity,
  likeCommentOfActivity,
  deleteCommentOfActivity,
  updateCommentOfActivity,
  getAllUsersMention
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
