/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./index.scss";
import MainForm from "./MainForm";
import DetailForm from "./DetailForm";

const SignUpWithRole = props => {
  const [step, setStep] = useState(1);
  const [form1, setForm1] = useState(null);
  const [form2, setForm2] = useState(null);
  const [isOldLeft13, setOldLeft13] = useState(false);

  const {
    match: {
      params: { role }
    }
  } = props;

  const onSubmit = () => {
    console.log(form1, form2);
  };

  return (
    <div className="sign-up-role">
      <div className="role-form">
        {step === 1 && (
          <MainForm
            onChangeStep={setStep}
            role={role}
            setForm1={setForm1}
            setOldLeft13={setOldLeft13}
          />
        )}

        {step === 2 && role && (
          <DetailForm
            role={role}
            setForm2={setForm2}
            onChangeStep={setStep}
            onSubmit={onSubmit}
            isOldLeft13={isOldLeft13}
            form1={form1}
          />
        )}
      </div>
    </div>
  );
};
export default SignUpWithRole;
