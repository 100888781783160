/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
// icons
import { AiFillWarning } from "react-icons/ai";
// ui
import { Card } from "antd";
import ButtonRegisterWithGoogle from "../../../components/ButtonRegisterWithGoogle";
import ButtonLoginGoogle from "../../../components/ButtonLoginGoogle";

const SignUpFailed = props => {
  const {
    responseGoogle,
    responseFailedGoogle,
    onLoginWithGoogle,
    onLoginWithGoogleFailed,
    msgErr
  } = props;

  return (
    <div className="sign-up-failed">
      <div className="content">
        <Card title="Create Teacher account">
          <div className="message">
            <AiFillWarning />
            <p>{msgErr}</p>
          </div>
          <div className="actions">
            <ButtonRegisterWithGoogle
              responseGoogle={responseGoogle}
              responseFailedGoogle={responseFailedGoogle}
            />
            <ButtonLoginGoogle
              onLoginWithGoogle={onLoginWithGoogle}
              onLoginWithGoogleFailed={onLoginWithGoogleFailed}
            />
          </div>
          <div className="more">
            <p>
              If you don’t have a Google account,{" "}
              <a
                href="https://accounts.google.com/signup?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </a>{" "}
              to create an account
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SignUpFailed;
