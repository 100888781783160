/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./index.scss";
import { MdCheckCircle } from "react-icons/md";
import Compress from "compress.js";
import { connect } from "react-redux";
import {
  Card,
  Row,
  Col,
  Input,
  Form,
  Select,
  Upload,
  Button,
  message
} from "antd";
import { URL_MEDIA } from "../../../../config";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { useHistory } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

const MainForm = props => {
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState(`${URL_MEDIA}images/user.svg`);
  const [loading, setLoading] = useState(false);
  const [fileResize, setFileResize] = useState(null);
  const [isConfirmAvatar, setConfirmAvatar] = useState(false);
  const [valuesForm, setValuesForm] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!props.userInitCreate) {
      history.push("/");
    }
  });

  const renderTitle = () => {
    if (props.role === "parent") {
      return "Parent";
    } else if (props.role === "student") {
      return "Student";
    } else {
      return "Teacher";
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onChangeGender = () => {};

  const onChangeAboutYou = () => {};

  const handleChange = async info => {
    const {
      file: { originFileObj }
    } = info;

    let compress = new Compress();

    await compress
      .compress([originFileObj], {
        size: 5,
        quality: 0.75,
        maxWidth: 300,
        maxHeight: 300,
        resize: true
      })
      .then(async data => {
        let file_resize = `${data[0].prefix} ${data[0].data}`;

        setImageUrl(file_resize);

        const img1 = data[0];
        const base64str = img1.data;
        const imgExt = img1.ext;

        const file = await Compress.convertBase64ToFile(base64str, imgExt);
        setFileResize(file);
      });
  };

  const renderSelectDays = () => {
    let options = [];
    for (let i = 1; i < 32; i++) {
      options.push(
        <Option key={i} value={String(i)}>
          {i}
        </Option>
      );
    }

    return <Select placeholder="Day">{options}</Select>;
  };

  const renderSelectYears = role => {
    let options = [];
    if (role === "student") {
      for (let i = 2015; i > 1979; i--) {
        options.push(
          <Option key={`y_${i}`} value={String(i)}>
            {i}
          </Option>
        );
      }
    } else {
      for (let i = 2003; i > 1902; i--) {
        options.push(
          <Option key={`y_${i}`} value={String(i)}>
            {i}
          </Option>
        );
      }
    }

    return <Select placeholder="Year">{options}</Select>;
  };

  const onFinish = async values => {
    try {
      const values = await form.validateFields();
      console.log("Success:", values);

      values.birthdayStr = `${values.year}-${values.month}-${values.day}`;
      setValuesForm(values);
      setConfirmAvatar(true);

      if (!fileResize) {
        setFileResize(true);
      } else {
        values.fileResize = fileResize;
        props.setForm1(values);
        props.onChangeStep(2);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfirmNext = async () => {
    await props.setForm1(valuesForm);
    await props.onChangeStep(2);
    await setValuesForm(false);
  };

  return (
    <div className="content main-form">
      <Card title={`Create ${renderTitle()} account`}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={24}>
            <Col sm={0} xs={24}>
              <div className="content-avatar">
                <div className="upload-avt">
                  <Form.Item name="avatar">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      // beforeUpload={beforeUpload}
                      onChange={handleChange}
                      accept=".png, .jpg, .jpeg"
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                      <p className="title-upload">Add Photo</p>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col md={14} sm={16} xs={24}>
              <div className="message">
                <MdCheckCircle />
                <p>Google authentication successful</p>
              </div>

              <div className="form-content">
                <Form.Item
                  name="first_name"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!"
                    }
                  ]}
                >
                  <Input placeholder="Your Name" />
                </Form.Item>

                <Form.Item
                  name="last_name"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!"
                    }
                  ]}
                >
                  <Input placeholder="Your Surname" />
                </Form.Item>

                <Form.Item label="Date of Birth">
                  <Row gutter={24}>
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="month"
                        rules={[
                          {
                            required: true,
                            message: "Month required!"
                          }
                        ]}
                      >
                        <Select placeholder="Month">
                          <Option value="01">Jan</Option>
                          <Option value="02">Feb</Option>
                          <Option value="03">Mar</Option>
                          <Option value="04">Apr</Option>
                          <Option value="05">May</Option>
                          <Option value="06">Jun</Option>
                          <Option value="07">Jul</Option>
                          <Option value="08">Aug</Option>
                          <Option value="09">Sep</Option>
                          <Option value="10">Oct</Option>
                          <Option value="11">Nov</Option>
                          <Option value="12">Dec</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="day"
                        rules={[
                          {
                            required: true,
                            message: "Day required!"
                          }
                        ]}
                      >
                        {renderSelectDays()}
                      </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                      <Form.Item
                        name="year"
                        rules={[
                          {
                            required: true,
                            message: "Year required!"
                          }
                        ]}
                      >
                        {renderSelectYears(props.role)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: "Please input your gender!"
                    }
                  ]}
                >
                  <Select onChange={onChangeGender} placeholder="Gender">
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                  </Select>
                </Form.Item>

                <Form.Item name="about_you" label="About You">
                  <TextArea
                    onChange={onChangeAboutYou}
                    placeholder="Tell more about yourself to the community"
                    rows={4}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    className="btn-save-continue"
                    // onClick={() => onChangeStep(2)}
                    htmlType="submit"
                  >
                    Save and continue
                  </Button>
                </Form.Item>
              </div>
            </Col>
            <Col md={10} sm={8} xs={0}>
              <div className="content-avatar">
                <div className="upload-avt">
                  <Form.Item name="avatar">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      // beforeUpload={beforeUpload}
                      onChange={handleChange}
                      accept=".png, .jpg, .jpeg"
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                      <p className="title-upload">Add Photo</p>
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </Card>

      <Modal
        className="popup-confirm-avatar"
        title="Continue without profile picture?"
        centered
        visible={isConfirmAvatar}
        okText="Yes"
        cancelText="No"
        onOk={() => onConfirmNext()}
        onCancel={() => setConfirmAvatar(false)}
      ></Modal>
    </div>
  );
};

const mapStateToProps = store => {
  return {
    userInitCreate: store.users.userInitCreate
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MainForm);
