import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'react-activity-feed/dist/index.css';
import 'emoji-mart/css/emoji-mart.css'
import './index.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as serviceWorker from './serviceWorker';
// redux
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./redux/reducers";

// components
import Main from './containers/Main';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
