/* eslint-disable react/prop-types */
import React from "react";
import "./index.scss";
import GoogleLogin from "react-google-login";
import { Button } from "antd";
import { AiOutlineGoogle } from "react-icons/ai";
import { GOOGLE_CLIENT_ID } from "../../config";

const ButtonRegisterWithGoogle = ({ responseGoogle, responseFailedGoogle }) => {
  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      render={renderProps => (
        <Button
          type="primary"
          htmlType="submit"
          className="btn-google"
          block
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <div className="title-btn">
            <AiOutlineGoogle size={24} />
            <span>Create an account with Google</span>
          </div>
        </Button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseFailedGoogle}
      cookiePolicy={"single_host_origin"}
    />
  );
};

export default ButtonRegisterWithGoogle;
