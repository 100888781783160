import React from "react";
import "./index.scss";
import { Switch, Route, BrowserRouter } from "react-router-dom";

// components
import Home from "../Home";
import Accounts from "../Accounts";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import SignUpWithRole from "../SignUp/SignUpWithRole";
import FeedsGetStream from "../FeedsGetStream";
import Menu from "../Home/Menu";


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main">
        <BrowserRouter>
          <Switch>
            <Route path="/dashboard" component={Home} />
            {/* <Route path="/feed-get-stream" component={FeedsGetStream} /> */}
            <Route path="/sign-in" exact component={SignIn} />
            <Route path="/accounts" component={Accounts} />
            <Route path="/auth/:role" exact component={SignUpWithRole} />
            <Route path="/menu" component={Menu} />
            <Route path="/" exact component={SignUp} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default Main;
