/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
// icons
import { AiTwotoneLike, AiOutlineWarning, AiOutlineLike } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit2 } from "react-icons/fi";
// import { GrDocumentText, GrDocumentPdf, GrDocumentCsv } from "react-icons/gr";
// import { FaRegFileExcel } from "react-icons/fa";
// ui
import { Row, Col, Dropdown, Menu, Button } from "antd";
import { URL_MEDIA } from "../../config";
import AddComment from "../AddComment";
import ViewLiked from "../ViewLiked";
import ViewComments from "../ViewComments";
import ViewCommented from "../ViewCommented";

const ContentPostPopup = props => {
  const {
    id,
    content,
    time,
    user: { first_name, last_name, avatar, _id, role },
    comments,
    likes
    // images,
    // attachments,
    // videos
  } = props.item;

  const [isShowLiked, setShowLiked] = useState(false);
  const [isShowCommented, setShowCommented] = useState(false);

  const checkMyLiked = likes => {
    let checked = likes.filter(el => String(el._id) === String(props.user._id));

    if (checked.length > 0) {
      return (
        <Button
          type="link"
          onClick={() => props.onLike(id)}
          className="me-liked"
        >
          <AiTwotoneLike /> Like
        </Button>
      );
    } else {
      return (
        <Button type="link" onClick={() => props.onLike(id)}>
          <AiOutlineLike /> Like
        </Button>
      );
    }
  };

  const renderLiked = likes => {
    let checked = likes.filter(el => String(el._id) === String(props.user._id));

    if (checked.length > 0) {
      if (likes.length > 1) {
        return `You and ${likes.length - 1} others`;
      } else {
        return `You`;
      }
    } else {
      return `${likes.length > 0 ? likes.length : ""} Like${
        likes.length > 1 ? "s" : ""
      }`;
    }
  };

  const renderLikesAndComments = (likes, comments) => {
    if (likes.length === 0 && comments.length === 0) {
      return "";
    } else {
      return (
        <div className="view-info">
          {likes.length > 0 && (
            <div className="view-likes" onClick={() => setShowLiked(true)}>
              <span className="ic-liked">
                <AiTwotoneLike />
              </span>
              {renderLiked(likes)}
            </div>
          )}

          {comments.length > 0 && (
            <div className="view-commented" onClick={() => setShowCommented(true)}>
              <span className="ic-commented">
                <img src={`${URL_MEDIA}images/cmt.svg`} alt="" />
              </span>
              {comments && comments.length ? comments.length : ""} Comment
              {comments && comments.length > 1 ? "s" : ""}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className="content-post-popup">
      <div className="activity-hd">
        <Row gutter={24}>
          <Col md={12}>
            <div className="user-info">
              <div className="avt">
                <img
                  src={avatar ? avatar.location : `${URL_MEDIA}images/user.svg`}
                  alt=""
                />
              </div>
              <div className="info">
                <h3>
                  {first_name ? first_name : ""} {last_name ? last_name : ""}
                </h3>
                <p>{role ? role : ""}</p>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="time-actions">
              <p className="time">
                {moment(moment.utc(time).local()).toNow(true)} ago
              </p>

              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu className="menu-post">
                    {String(props.user._id) === _id ? (
                      <>
                        <Menu.Item key="0">
                          <FiEdit2 /> Edit
                        </Menu.Item>
                        <Menu.Item key="1">
                          <RiDeleteBin6Line /> Delete
                        </Menu.Item>
                      </>
                    ) : (
                      <Menu.Item key="0">
                        <AiOutlineWarning /> Flag as inappropriate
                      </Menu.Item>
                    )}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  type="link"
                  className="ant-dropdown-link"
                  onClick={e => e.preventDefault()}
                >
                  <img src={`${URL_MEDIA}images/dots.svg`} alt="" />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <div className="activity-content">{content ? content : ""}</div>

      <div className="act-actions">
        {/* <div className="view-info">
          <div className="view-likes" onClick={() => setShowLiked(true)}>
            <span className="ic-liked">
              <AiTwotoneLike />
            </span>
            {renderLiked(likes)}
          </div>

          <div className="view-commented">
            <span className="ic-commented">
              <img src={`${URL_MEDIA}images/cmt.svg`} alt="" />
            </span>
            {comments && comments.length ? comments.length : ""} Comment
            {comments && comments.length > 1 ? "s" : ""}
          </div>
        </div> */}
        {renderLikesAndComments(likes, comments)}

        <Row gutter={24}>
          <Col md={12}>
            <div className="action-item">{checkMyLiked(likes)}</div>
          </Col>
          <Col md={12}>
            <div className="action-item">
              <Button type="link">
                <img src={`${URL_MEDIA}images/msg1.svg`} alt="" /> Comment
              </Button>
            </div>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col md={12}>
            <div className="action-item">
              <Button type="link" onClick={() => props.onLikePopup(id)}>
                {checkMyLiked(likes)} {likes ? likes.length : 0}
              </Button>
              <Button
                type="link"
                className="btn-view-liked"
                onClick={() => setShowLiked(true)}
              >
                Like
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <div className="action-item">
              <Button type="link">
                <img src={`${URL_MEDIA}images/msg1.svg`} alt="" />{" "}
                {comments ? comments.length : 0} Comment
              </Button>
            </div>
          </Col>
        </Row> */}
      </div>



      <div className="comments">
        <AddComment user={props.user} onComment={props.handleAddComment} />
      </div>

      {comments.length > 0 && (
        <ViewComments
          comments={comments}
          onLikeComment={props.onLikeComment}
          user={props.user}
          onDeleteComment={props.onDeleteComment}
          onUpdateComment={props.onUpdateComment}
        />
      )}

      <ViewLiked isShow={isShowLiked} onShow={setShowLiked} likes={likes} />

      <ViewCommented
        isShow={isShowCommented}
        onShow={setShowCommented}
        comments={comments}
      />
    </div>
  );
};

export default ContentPostPopup;
