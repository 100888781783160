import React from "react";
import "./index.scss";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div className="profile">Profile</div>;
  }
}

export default Profile;
