import API from '../request'

export function getActiviesOfFeed() {
  return dispatch => {
    return API().get(`feeds/activities`).then(res => {
      dispatch({
        type: "ACTION_GET_ACTIVITIES_FEED",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_GET_ACTIVITIES_FEED",
      })
    })
  }
}

export function getActiviesOfFeedHashtag(hashtag) {
  return dispatch => {
    dispatch({ type: 'ACTION_RESET_ACTIVITIES_FEED' })
    return API().get(`feeds/activities/hashtag`, { hashtag }).then(res => {
      dispatch({
        type: "ACTION_GET_ACTIVITIES_FEED",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_GET_ACTIVITIES_FEED",
      })
    })
  }
}

export function createActivityOfFeed(data) {
  return dispatch => {
    return API().post(`feeds/activities/create`, data).then(res => {
      dispatch({
        type: "ACTION_CREATE_ACTIVITY",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_CREATE_ACTIVITY",
      })
    })
  }
}

export function deleteActivity(activityId) {
  return dispatch => {
    return API().post(`feeds/activities/delete`, { activityId }).then(res => {
      dispatch({
        type: "ACTION_DELETED_ACTIVITY",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_DELETED_ACTIVITY",
        msgErr: err.message
      })
    })
  }
}

export function updateActivity(activityId, data) {
  return dispatch => {
    return API().put(`feeds/activities/update/${activityId}`, data).then(res => {
      dispatch({
        type: 'ACTION_UPDATED_ACTIVITY',
        data: res.data
      })
    }).catch(err => {
      dispatch({
        type: "ERROR_UPDATED_ACTIVITY",
        msgErr: err.message
      })
    })
  }
}

export function likeActivity(activityId) {
  return dispatch => {
    return API().post(`feeds/activities/likes/${activityId}`).then(res => {
      dispatch({
        type: "ACTION_LIKE_ACTIVITY",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_LIKE_ACTIVITY",
        msgErr: err.message
      })
    })
  }
}

export function commentActivity(activityId, data) {
  return dispatch => {
    return API().post(`feeds/activities/comments/${activityId}`, data).then(res => {
      dispatch({
        type: "ACTION_COMMENT_ACTIVITY",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_COMMENT_ACTIVITY",
        msgErr: err.message
      })
    })
  }
}

export function likeCommentOfActivity(commentId) {
  return dispatch => {
    return API().post(`feeds/comments/likes/${commentId}`, {}).then(res => {
      dispatch({
        type: "ACTION_LIKED_COMMENT",
        data: res.data
      })
    }).catch(err => {
      console.log("err: ", err)
      dispatch({
        type: "ERROR_LIKED_COMMENT",
      })
    })
  }
}

export function deleteCommentOfActivity(commentId) {
  return dispatch => {
    return API().delete(`feeds/comments/delete/${commentId}`).then(res => {
      dispatch({
        type: "ACTION_DELETED_COMMENT",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_DELETED_COMMENT",
        msgErr: "Deleted comment failed!"
      })
    })
  }
}

export function updateCommentOfActivity(commentId, data) {
  return dispatch => {
    return API().put(`feeds/comments/update/${commentId}`, data).then(res => {
      dispatch({
        type: "ACTION_UPDATED_COMMENT",
        data: res.data
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: "ERROR_UPDATED_COMMENT",
        msgErr: "Updated comment failed!"
      })
    })
  }
}

export default {
  getActiviesOfFeed,
  createActivityOfFeed,
  deleteActivity,
  updateActivity,
  likeActivity,
  commentActivity,
  likeCommentOfActivity,
  deleteCommentOfActivity,
  updateCommentOfActivity
}